import React from 'react';
//dev component
import CHelper from '../../components/CHelper';
import DropDown from '../../components/DropDown';
import Calender from '../../components/Calender';
import { AdvancedSearchCtnr } from './exportCtnr';

var Include = [
	{'Id': 0, 'Name': I('All Errands')},
	{'Id': 1, 'Name': I('Search Errands')}
];
var TimeFrameType = [
    { 'Id': 0, 'Name': I('Relative') },
	{ 'Id': 1, 'Name': I('Absolute') }
];

export class AdvancedSearch extends React.Component {
	constructor(props) {
		super(props);
	}
	componentWillMount = () => {}
	componentDidMount = () => {}
	stringToArray = (items) => {
		let a = []
		if(items != "") {
			a = items.split(",").map(function(x){
				return parseInt(x, 10);
			});
		}
		return a;
	}
	getTags = () => {
		var tags = [];
		this.props.tags.map(function(item, i) {
			if(item.Tags != null) {
				item.Tags.map(function(t, i){
					tags.push({'Id': t.id, 'Name': item.name + " - " + t.name });
				});
			}
		});
		return tags;
	}
	generateEntryRow = (flag, items) => {
		var list = [];
		items.map(function(item, i) {
			var l = (
				<div className="remove-attach" key={flag+"_"+i}>
					<i style={{cursor: 'pointer'}} onClick={(e) => this.handleRemoveSearchTerm(flag, item)} className="glyphicon glyphicon-remove"/>
					<span id="selectItem" style={{paddingLeft: '5px'}}>{item}</span>
			    </div>
			);
			list.push(l);
		}, this);
		return (
			<div className="form-group">
				<div className="col-lg-9 col-sm-9 col-xs-9" style={{"paddingLeft": "30px"}}>
					{list}
				</div>
				<div className="clear"></div>
			</div>
		);
	}
	valueExistInArray = (array, id) => {
		for(let i = 0 ; i< array.length; i++){
			if(array[i]== id){
				return true;
			}
		}
		return false;
	}
    renderCategoryCheckbox = (flag, items) => {
		var list = [];
		this.props.export.errandCategory.map(function(c, i) {
			var l = (
				<div key={flag+"_"+i}>
					<input type="checkbox"
						key={"category_" + flag + c.id}
						checked={this.valueExistInArray(items, c.id)}
						value={c.id}
						onChange={(e) => this.handleErrandCategory(flag, e)}
					/>
					<span> {c.name}</span>
					<br />
				</div>
			);
			list.push(l);
		}, this);
		return (
			<div className="col-lg-9 col-sm-4 col-xs-7" style={{"paddingLeft": "33px"}}>
				{list}
			</div>
		);
	}
	handleErrandCategory = (flag, e) => {
		if(e.target.checked == true) {
			this.props.onSetErrandCategory(flag, parseInt(e.target.value, 10));
		} else {
			this.props.onRemoveErrandCategory(flag, parseInt(e.target.value, 10));
		}
	}
	handlePositiveTags = items => {
		let a = this.stringToArray(items);
		this.props.onSetValue('positiveTags', a);
	}
	handleNegativeTags = items => {
		let a = this.stringToArray(items);
		this.props.onSetValue('negativeTags', a);
	}
	handleSavePsWords = () => {
		if(this.positiveWords.value != "") {
			this.props.onSetValue('positiveWords', this.positiveWords.value);
		}
		this.positiveWords.value = "";
	}
	handleSaveNgWords = () => {
		if(this.negativeWords.value != "") {
			this.props.onSetValue('negativeWords', this.negativeWords.value);
		}
		this.negativeWords.value = "";
	}
	handleSavePsFromAddress = () => {
		if(this.positiveFromAddress.value != "") {
			this.props.onSetValue('positiveFromAddress', this.positiveFromAddress.value);
		}
		this.positiveFromAddress.value = "";
	}
	handleSaveNgFromAddress = () => {
		if(this.negativeFromAddress.value != "") {
			this.props.onSetValue('negativeFromAddress', this.negativeFromAddress.value);
		}
		this.negativeFromAddress.value = "";
	}
	handleRemoveSearchTerm = (flag, item) => {
		this.props.onRemoveSearchTerm(flag, item);
	}
	render() {
		return (
			<div>
				<div className="form-group">
					<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
						{I("Errands must have at least one of the following categories")}
					</label>
					<div className="clear"></div>
				</div>
				<div className="form-group">
					{this.renderCategoryCheckbox("positiveCategory", this.props.export.positiveCategory)}
					<div className="clear"></div>
				</div>
				<div className="form-group">
					<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
						{I("Errands must have none of the following categories")}
					</label>
					<div className="clear"></div>
				</div>
				<div className="form-group">
					{this.renderCategoryCheckbox("negativeCategory", this.props.export.negativeCategory)}
					<div className="clear"></div>
				</div>
				<div className="form-group">
					<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
						{I("Errands must have at least one of the following tags")}
					</label>
					<div className="clear"></div>
				</div>
				<div className="form-group">
					<div className="col-lg-12 col-sm-4 col-xs-7 control-multiselect">
						<DropDown
							id="PositiveTags"
							name={I("Select Tags")}
							textNoItem={I("No tag")}
							textChooseItem={I("Choose Tags")}
							textNoItemSelected={I("No tag selected")}
							textSelectedItem={I("Selected Tags")}
							type={"open-multiselect"}
							items={this.getTags()}
							selectedItems={this.props.export.positiveTags.toString()}
							align="left"
							fields={{id: "Id", name: "Name"}}
							onChange={this.handlePositiveTags}
							activeWhen="Active"
							multiSelect={true}
							wantSelectAll={true}
							wantSelectNone={true}
							showAllSelected={true}
						/>
					</div>
					<div className="clear"></div>
				</div>
				<div className="form-group">
					<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
						{I("Errands must have none of the following tags")}
					</label>
					<div className="clear"></div>
				</div>
				<div className="form-group">
					<div className="col-lg-12 col-sm-4 col-xs-7 control-multiselect">
						<DropDown
							id="negativeTags"
							name={I("Tags")}
							textNoItem={I("No tag")}
							textChooseItem={I("Choose Tags")}
							textNoItemSelected={I("No tag selected")}
							textSelectedItem={I("Selected Tags")}
							type={"open-multiselect"}
							items={this.getTags()}
							selectedItems={this.props.export.negativeTags.toString()}
							align="left"
							fields={{id: "Id", name: "Name"}}
							onChange={this.handleNegativeTags}
							activeWhen="Active"
							multiSelect={true}
							wantSelectAll={true}
							wantSelectNone={true}
							showAllSelected={true}
						/>
					</div>
					<div className="clear"></div>
				</div>
				<div className="form-group">
					<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
						{I("Errands must include at least one of these words")}
					</label>
					<div className="clear"></div>
				</div>
				<div className="form-group">
					<div className="col-lg-9 col-sm-9 col-xs-9">
						<div className="col-md-11" style={{paddingRight: '0px'}}>
							<input type="text" className='form-control input-sm'
								placeholder={I("Search Terms")}
								ref={(ref) => this.positiveWords = ref}
							/>
						</div>
						<div className="col-md-1" style={{paddingLeft: '5px'}}>
							<button className="btn btn-primary btn-sm"
								type="button" onClick={this.handleSavePsWords}>
								{I('Add')}
							</button>
						</div>
					</div>
					<div className="clear"></div>
				</div>
				{this.generateEntryRow("positiveWords", this.props.export.positiveWords)}
				<div className="form-group">
					<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
						{I("Errands must not include any of these words")}
					</label>
					<div className="clear"></div>
				</div>
				<div className="form-group">
					<div className="col-lg-9 col-sm-9 col-xs-9">
						<div className="col-md-11" style={{paddingRight: '0px'}}>
							<input type="text" className='form-control input-sm'
								placeholder={I("Search Terms")}
								ref={(ref) => this.negativeWords = ref}
							/>
						</div>
						<div className="col-md-1" style={{paddingLeft: '5px'}}>
							<button className="btn btn-primary btn-sm"
								type="button" onClick={this.handleSaveNgWords}>
								{I('Add')}
							</button>
						</div>
					</div>
					<div className="clear"></div>
				</div>
				{this.generateEntryRow("negativeWords", this.props.export.negativeWords)}
				<div className="form-group">
					<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
						{I("Errands from address must include at least one of these search terms")}
					</label>
					<div className="clear"></div>
				</div>
				<div className="form-group">
					<div className="col-lg-9 col-sm-9 col-xs-9">
						<div className="col-md-11" style={{paddingRight: '0px'}}>
							<input type="text" className='form-control input-sm'
								placeholder={I("Search Terms")}
								ref={(ref) => this.positiveFromAddress = ref}
							/>
						</div>
						<div className="col-md-1" style={{paddingLeft: '5px'}}>
							<button className="btn btn-primary btn-sm"
								type="button" onClick={this.handleSavePsFromAddress}>
								{I('Add')}
							</button>
						</div>
					</div>
					<div className="clear"></div>
				</div>
				{this.generateEntryRow("positiveFromAddress", this.props.export.positiveFromAddress)}
				<div className="form-group">
					<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
						{I("Errands from address must not include any of these search terms")}
					</label>
					<div className="clear"></div>
				</div>
				<div className="form-group">
					<div className="col-lg-9 col-sm-9 col-xs-9">
						<div className="col-md-11" style={{paddingRight: '0px'}}>
							<input type="text" className='form-control input-sm'
								placeholder={I("Search Terms")}
								ref={(ref) => this.negativeFromAddress = ref}
							/>
						</div>
						<div className="col-md-1" style={{paddingLeft: '5px'}}>
							<button className="btn btn-primary btn-sm"
								type="button" onClick={this.handleSaveNgFromAddress}>
								{I('Add')}
							</button>
						</div>
					</div>
					<div className="clear"></div>
				</div>
				{this.generateEntryRow("negativeFromAddress", this.props.export.negativeFromAddress)}
			</div>
		);
	}
}

class EditExport extends React.Component {
	constructor(props) {
		super(props);
	}
	componentWillMount = () => {
		this.props.onFetchTags(this.props.export.area);
	}
	formValidation = () => {
		let r = true
		if(this.props.export.name == "") {
			$(".with-errors-name").html(I("* You can not leave the name field empty."))
			r = false
		}
		if(this.props.export.emailExport == true &&
			this.props.export.emailAddress == "") {
			$(".with-errors-emailAddress").html(I("* You can not leave the email address empty."))
			r = false
		}
		if(r == true) {
			$(".with-errors-name").html("")
			$(".with-errors-emailAddress").html("")
		}
		return r;
	}
	getHours = () => {
		var hours = [];
		for (var i = 0; i < 24; i++) {
			var hoursObj = {};
			if(i < 10){
				hoursObj = {'Id': i,  'Name' : ('0'+i).toString() };
			}else{
				hoursObj = {'Id': i,  'Name' : i.toString() };
			}
			hours.push(hoursObj);
		}
		return hours;
	}
	getMinutes = () => {
		var minutes = [];
		for (var i = 0; i < 60; i++) {
			var minutesObj = {};
			if(i < 10){
				minutesObj = {'Id': i,  'Name' : ('0'+i).toString() };
			}else{
				minutesObj = {'Id': i,  'Name' : i.toString() };
			}
			minutes.push(minutesObj);
		}
		return minutes;
	}
	stringToArray = (items) => {
		let a = []
		if(items != "") {
			a = items.split(",").map(function(x){
				return parseInt(x, 10);
			});
		}
		return a;
	}
	renderEmailView = () => {
		if(this.props.export.emailExport == true) {
			return (
				<div>
					<div className="form-group">
						<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
							{I('Email address')}<span className="mandatory"> *</span>
						</label>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<div className="col-lg-9 col-sm-4 col-xs-7" style={{"paddingLeft": "30px"}}>
							<input type="text" className='form-control input-sm'
								placeholder={I('Email address')}
								onChange={this.handleEmailAddress}
								value={this.props.export.emailAddress}
							/>
							<div className="help-block with-errors-emailAddress"
								key={"emailAddress_" + this.props.export.id}
								style={{color: 'red'}}>
							</div>
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
							{I('Email Subject')}<span className="mandatory"> *</span>
						</label>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<div className="col-lg-9 col-sm-4 col-xs-7" style={{"paddingLeft": "30px"}}>
							<input type="text" className='form-control input-sm'
								placeholder={I('Email Subject')}
								onChange={this.handleEmailHeader}
								value={this.props.export.emailHeader}
							/>
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
							{I('Email Body')}<span className="mandatory"> *</span>
						</label>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<div className="col-lg-9 col-sm-4 col-xs-7" style={{"paddingLeft": "30px"}}>
							<textarea className='form-control input-sm'
								placeholder={I('Email Body')}
								onChange={this.handleEmailBody}
								value={this.props.export.emailBody}
							/>
						</div>
						<div className="clear"></div>
					</div>
				</div>
			);
		} else {
			return (
				<div></div>
			);
		}
	}
	renderTimeFrameType= () => {
		if(this.props.export.time == "10") {
			return (<div>
			<div className="form-group">
				<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
					{I("Time frame type")}
				</label>
				<div className="clear"></div>
			</div>
			<div className="form-group">
				<div className="col-lg-9 col-sm-4 col-xs-7" style={{"paddingLeft": "30px"}}>
					<DropDown
						id={"Time"}
						name={"Time"}
						selectedItems={this.props.export.timeFrameType.toString()}
						items={TimeFrameType}
						fields={{id: "Id", name: "Name"}}
						defaultSelectNone={false}
						multiSelect={false}
						wantSelectAll={false}
						wantSelectNone={false}
						wantShowDone={false}
						onChange={this.handleTimeFrame}
					/>
				</div>
				<div className="clear"></div>
			</div>
			</div>
			);
		}
	}
	renderTimeFrame() {
		if(this.props.export.timeFrameType == "1" && this.props.export.time == "10") {
			return (<div>
			<div className="form-group">
				<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
					{I('Errand interval')}
				</label>
				<div className="clear"></div>
			</div>
			<div className="form-group">
				<div className="col-lg-9 col-sm-4 col-xs-7" style={{"paddingLeft": "30px", "display": "flex"}}>
					<Calender id="ExportStartDate" selectedDate={this.props.export.absoluteTimeFrom} onChange={this.handleStartDate}/>
					<label style={{"padding": "5px", "verticalAlign" : "top"}}> - </label>
					<Calender id="ExportEndDate" selectedDate={this.props.export.absoluteTimeTo} onChange={this.handleEndDate}/>
				</div>
				<div className="clear"></div>
			</div>
			</div>
			);
		} else {
			return (<div>
			<div className="form-group">
				<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
					{I("Errand Interval")}
				</label>
				<div className="clear"></div>
			</div>
			<div className="form-group">
				<div className="col-lg-9 col-sm-4 col-xs-7" style={{"paddingLeft": "30px"}}>
					<DropDown
						id={"Interval"}
						name={"Interval"}
						selectedItems={this.props.export.searchInterval.toString()}
						items={this.props.list.searchInterval}
						fields={{id: "Id", name: "Name"}}
						defaultSelectNone={false}
						multiSelect={false}
						wantSelectAll={false}
						wantSelectNone={false}
						wantShowDone={false}
						onChange={this.handleRelativeInterval}
					/>
				</div>
				<div className="clear"></div>
			</div>
			</div>
			);
		}
	}
	handleName = e => {
		this.props.onSetValue('name', e.target.value)
	}
	handleDescription = e => {
		this.props.onSetValue('description', e.target.value)
	}
	handlePrivacyCheck = e => {
		this.props.onSetValue('privacyBool', e.target.checked)
		this.props.onSetValue('privacy', (e.target.checked?"Yes":"No"))
	}
	handleChannelChange = items => {
		this.props.onSetValue('channel', this.stringToArray(items))
	}
	handleEmailExport = e => {
		this.props.onSetValue('emailExport', e.target.checked)
	}
	handleEmailAddress = e => {
		this.props.onSetValue('emailAddress', e.target.value)
	}
	handleEmailHeader = e => {
		this.props.onSetValue('emailHeader', e.target.value)
	}
	handleEmailBody = e => {
		this.props.onSetValue('emailBody', e.target.value)
	}
	handleRunInterval = item => {
		this.props.onSetValue('time', parseInt(item, 10))
	}
	handleSelectHour = item => {
		this.props.onSetValue('hour', parseInt(item, 10))
	}
	handleSelectMinute = item => {
		this.props.onSetValue('minute', parseInt(item, 10))
	}
	handleTimeFrame = item => {
		this.props.onSetValue('timeFrameType', parseInt(item, 10))
	}
	handleTimeZone = item => {
		this.props.onSetValue('timeZone', parseInt(item, 10))
	}
	handleSelectArea = items => {
		let a = this.stringToArray(items)
		this.props.onSetValue('area', a)
		this.props.onFetchTags(a)
	}
	handleDisplayKey = items => {
		this.props.onSetValue('displayKey', this.stringToArray(items))
	}
	handleFileType = item => {
		this.props.onSetValue('fileType', parseInt(item, 10))
	}
	handleInclude = item => {
		this.props.onSetValue('include', (item == "0" ? true : false))
	}
	handleRelativeInterval = item => {
		this.props.onSetValue('searchInterval', parseInt(item, 10))
	}
	handleStartDate = c => {
		this.props.onSetValue('absoluteTimeFrom', c)
	}
	handleEndDate = c => {
		this.props.onSetValue('absoluteTimeTo', c)
	}
	handleCancel = () => {
		this.props.onCancel()
	}
	handleGenExport = () => {
		this.props.onFetchGenExport(this.props.export.id)
	}
	handleSave = () => {
		if(this.formValidation()) {
			if(this.props.export.id > 0) {
				this.props.onSave()
			} else {
				this.props.onSetValue('activate', true)
				this.props.onCreateNew()
			}
		}
	}
	handleRunNow = () => {
		if(this.props.export.id > 0) {
			this.props.onSetValue('activate', true)
		}
		this.handleSave()
	}
	handlePreview = () => {
		this.props.onGeneratePreview()
	}
	render() {
		let downloadLink = (
			<span className="page-header-small-title" style={{float:"right"}}>
				{I("Exports")} | {I("Download")} |
				<a href="#" onClick={this.handleGenExport}>
					<span className="glyphicon glyphicon-save" style={{paddingLeft:"5px"}}></span>
				</a>
			</span>
		)
		return (
			<div className="panel panel-default">
				<div className="panel-heading" style={{padding: "12px 12px 26px"}}>
						<span className="page-header-small-title" style={{float:"left"}}>
							{this.props.export.id > 0 ? I("Exports")+" | "+I("Edit") : I("Exports")+" | "+I("Create New")}
						</span>
						{this.props.export.id > 0 ? downloadLink : ""}
				</div>
				<div className="panel-body">
					<div className="form-group">
						<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
							{I('Export Name')}<span className="mandatory"> *</span>
						</label>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<div className="col-lg-9 col-sm-4 col-xs-7" style={{"paddingLeft": "30px"}}>
							<input type="text" className='form-control input-sm'
								placeholder={I('Export Name')}
								onChange={this.handleName}
								value={this.props.export.name}
							/>
							<div className="help-block with-errors-name"
								key={"name_" + this.props.export.id}
								style={{color: 'red'}}>
							</div>
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
							{I('Export Description')}<span className="mandatory"></span>
						</label>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<div className="col-lg-9 col-sm-4 col-xs-7" style={{"paddingLeft": "30px"}}>
							<textarea className='form-control input-sm'
								placeholder={I('Export Description')}
								onChange={this.handleDescription}
								value={this.props.export.description}
							/>
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<label className="col-lg-5 col-sm-3 col-xs-3 control-label" htmlFor="basic">
							{I('Export only viewable for this user')}
						</label>
						<div className="col-lg-7 col-sm-4 col-xs-7">
							<input key={"p-check-box" + this.props.export.id}
								type="checkbox"
								checked={this.props.export.privacybool}
								onChange={this.handlePrivacyCheck}
							/>
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<label className="col-lg-5 col-sm-3 col-xs-3 control-label" htmlFor="basic">
							{I('Email export when complete')}
						</label>
						<div className="col-lg-3 col-sm-1 col-xs-1">
							<input key={"eex-check-box" + this.props.export.id}
								type="checkbox"
								checked={this.props.export.emailExport}
								onChange={this.handleEmailExport}
							/>
						</div>
						<div className="col-lg-4 col-sm-8 col-xs-8">
							<CHelper previewMessage={I("This option will result in an email being sent out to the E-mail address configured. The generated file will be included in the E-mail for downloading..")}
							 pos="left"/>
						</div>
						<div className="clear"></div>
					</div>
					{this.renderEmailView()}
					<div className="form-group">
						<label className="col-lg-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">
							{I("Time Zone")}
						</label>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<div className="col-lg-9 col-sm-4 col-xs-7" style={{"paddingLeft": "30px"}}>
							<DropDown
								id={"TimeZone"}
								name={"Time Zone"}
								selectedItems={this.props.export.timeZone.toString()}
								items={this.props.list.timeZone}
								fields={{id: "Id", name: "Name"}}
								defaultSelectNone={false}
								multiSelect={false}
								wantSelectAll={false}
								wantSelectNone={false}
								wantShowDone={false}
								onChange={this.handleTimeZone}
							/>
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
							{I("Schedule time to run export")}
						</label>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<div className="col-lg-9 col-sm-4 col-xs-7" style={{"paddingLeft": "30px"}}>
							<DropDown
								id={"RunInterval"}
								name={"Run Interval"}
								selectedItems={this.props.export.time.toString()}
								items={this.props.list.runInterval}
								fields={{id: "Id", name: "Name"}}
								defaultSelectNone={false}
								multiSelect={false}
								wantSelectAll={false}
								wantSelectNone={false}
								wantShowDone={false}
								onChange={this.handleRunInterval}
							/>
							<p className="hourSeparator"></p>
							<DropDown
								id="ReportHours"
								name={I("Hours")}
								selectedItems={this.props.export.hour.toString()}
								items={this.getHours()}
								align="left"
								fields={{id: "Id", name: "Name"}}
								onChange={this.handleSelectHour}
								wantSelectNone={false}
							/>
							<p className="hourSeparator">:</p>
							<DropDown
								id="ReportMinutes"
								name={I("Minutes")}
								selectedItems={this.props.export.minute.toString()}
								items={this.getMinutes()}
								align="left"
								fields={{id: "Id", name: "Name"}}
								onChange={this.handleSelectMinute}
								wantSelectNone={false}
							/>
						</div>
						<div className="clear"></div>
					</div>
					{this.renderTimeFrameType()}
					{this.renderTimeFrame()}
					<div className="form-group">
						<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
							{I("Channels")}
						</label>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<div className="col-lg-9 col-sm-4 col-xs-7" style={{"paddingLeft": "30px"}}>
							<DropDown
								id={"Channels"}
								name={"Channels"}
								textNoItem={I("No Channels")}
								textChooseItem={I("Choose Channels")}
								textNoItemSelected={I("No Channels selected")}
								textSelectedItem={I("Selected Channels")}
								selectedItems={this.props.export.channel.toString()}
								items={this.props.list.channel}
								fields={{id: "Id", name: "Name"}}
								defaultSelectNone={false}
								multiSelect={true}
								wantSelectAll={true}
								wantSelectNone={true}
								wantShowDone={true}
								defaultSelectAll={this.props.export.channel.length > 0 ? false : true}
								onChange={this.handleChannelChange}
							/>
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
							{I("Source for Errands")}
						</label>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<div className="col-lg-12 col-sm-4 col-xs-7 control-multiselect">
							<DropDown
								id="SourceArea"
								name={I("Area")}
								textNoItem={I("No Areas")}
								textChooseItem={I("Choose Areas")}
								textNoItemSelected={I("No Areas selected")}
								textSelectedItem={I("Selected Areas")}
								type={"open-multiselect"}
								items={this.props.areas}
								selectedItems={this.props.export.area.toString()}
								align="left"
								fields={{id: "Id", name: "Name"}}
								onChange={this.handleSelectArea}
								activeWhen="Active"
								multiSelect={true}
								wantSelectAll={true}
								wantSelectNone={true}
								showAllSelected={true}
							/>
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
							{I("Columns to display in export")}
						</label>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<div className="col-lg-12 col-sm-4 col-xs-7 control-multiselect">
							<DropDown
								id="display_key"
								name={I("Export Display Key")}
								textNoItem={I("No Display Keys")}
								textChooseItem={I("Choose Display Keys")}
								textNoItemSelected={I("No Display Keys Selected")}
								textSelectedItem={I("Selected Display Keys")}
								type={"open-multiselect"}
								items={this.props.export.allDisplayKey}
								selectedItems={this.props.export.displayKey.toString()}
								align="left"
								fields={{id: "Id", name: "Name"}}
								onChange={this.handleDisplayKey}
								activeWhen="Active"
								multiSelect={true}
								wantSelectAll={true}
								wantSelectNone={true}
								showAllSelected={true}
							/>
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<label className="col-lg-3 col-sm-3 col-xs-3 control-label" htmlFor="basic">
							{I("Export File Type")}
						</label>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<div className="col-lg-9 col-sm-4 col-xs-7" style={{"paddingLeft": "30px"}}>
							<DropDown
								id={"fileType"}
								name={I("File Type")}
								selectedItems={this.props.export.fileType.toString()}
								items={this.props.list.fileType}
								fields={{id: "Id", name: "Name"}}
								defaultSelectNone={false}
								multiSelect={false}
								wantSelectAll={false}
								wantSelectNone={false}
								wantShowDone={false}
								onChange={this.handleFileType}
							/>
						</div>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<label className="col-lg-12 col-sm-3 col-xs-3 control-label" htmlFor="basic">
							{I("Advanced Export")}
						</label>
						<div className="clear"></div>
					</div>
					<div className="form-group">
						<div className="col-lg-9 col-sm-4 col-xs-7" style={{"paddingLeft": "30px"}}>
							<DropDown
								id={"SearchType"}
								name={"Search Type"}
								selectedItems={this.props.export.include == true ? "0" : "1"}
								items={Include}
								fields={{id: "Id", name: "Name"}}
								defaultSelectNone={false}
								multiSelect={false}
								wantSelectAll={false}
								wantSelectNone={false}
								wantShowDone={false}
								onChange={this.handleInclude}
							/>
						</div>
						<div className="clear"></div>
					</div>
					{this.props.export.include == true ? "" : <AdvancedSearchCtnr export={this.props.export} />}
					<div className="form-group" style={{"paddingTop": "30px"}}>
						<div className="col-lg-12 textAlignRight">
							<button className="btn btn-warning btn-sm"
								type="button" onClick={this.handleCancel}>
								{I('Cancel')}
							</button>
							<span style={{'marginRight':'5px'}}></span>
							<button className="btn btn-info btn-sm"
								type="button" onClick={this.handlePreview}>
								{I('Preview')}
							</button>
							<span style={{'marginRight':'5px'}}></span>
							<button className="btn btn-success btn-sm"
								type="button" onClick={this.handleSave}>
								{I('Save')}
							</button>
							<span style={{'marginRight':'5px'}}></span>
							<button className="btn btn-primary btn-sm"
								type="button" onClick={this.handleRunNow}>
								{I('Run now')}
							</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default EditExport;
