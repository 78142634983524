import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import each from 'lodash/each'
import classNames from 'classnames'
import { branch, renameProps, renderComponent, withProps } from 'recompose'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import { SMTP } from '../../common/path'
import {
  AUTH_GOOGLE_OAUTH2,
  AUTH_MICROSOFT_OAUTH2,
  AUTH_BASIC,
  TXT_AUTHORIZATION,
  emptyArray,
  emptyObject,
  smtpAuthorizationOption
} from '../../common/constants'
import { UNSELECT } from '../../common/v5/constants'
import {
  isNumberOrNumberStringEqual,
  receiptGreetingChange,
  simpleChange
} from '../../common/helpers'
import {
  TXT_ADD_NEW,
  TXT_IDENTITY,
  TXT_SAVE,
  TXT_VERIFIED
} from '../../common/texts'
import { AEO_MANUAL, TXT_ACTION } from '../../common/v5/chatbotConstants'
import {
  DKIM_AWS_SES,
  ONLY_ROOT_EDIT_DEF,
  TXT_AUTHORIZED_ACCESS_TOKEN,
  TXT_DEFAULT,
  TXT_IDENTITY_DOMAIN_EMAIL,
  TXT_MAIL_FROM_DOMAIN,
  TXT_PASSWORD,
  TXT_PORT,
  TXT_RELOAD_DB,
  TXT_SECURE,
  TXT_SERVER,
  TXT_SMTP,
  TXT_USE_AWS_SES,
  TXT_USERNAME,
  initAWSSES,
  initEditValue,
  pathParams,
  toEditPath,
  toListPath,
  toSelectAWSSESIdentity,
  TXT_USE_AWS_SES_API,
  TXT_HELPER_AWS_SES_API
} from '../../common/v5/smtpConstants'
import { centionYellow } from '../../styles/_variables'
import flexbox from '../../styles/flexbox'
import {
  useCallbackMultiValues,
  useCallbackWithValue,
  useIgnorePromiseCatch
} from '../../hooks/callback'
import { useRegionParam } from '../../hooks/state'
import {
  composeWithDisplayName,
  createWithMountCondition,
  omitProps,
  withUnmountWhenHidden
} from '../../reactcomponents/hocs'
import {
  ACTION_COLLAPSE,
  ACTION_DELETE,
  ACTION_EDIT,
  ACTION_EXPAND,
  ACTION_RELOAD,
  ACTION_COLLAPSE_EXPAND,
  DoubleDecks,
  EditorBox,
  HideableDiv,
  ListHeaderWrapper,
  StandardEditorHeader,
  TableHeader,
  Skeleton
} from '../../reactcomponents/Admin'
import AWSSESIdentityDetailTable from '../../reactcomponents/AWSSESTable'
import AuthorizationSelect, {
  AuthorizedToken,
  justAuthorizedTokenProvider
} from '../../reactcomponents/Authorization'
import { useChangeDetectSave } from '../../reactcomponents/Chatbot'
import {
  ActiveBorderHighlightNormSingleSelect,
  PlainArraySingleSelect
} from '../../reactcomponents/Dropdown'
import { CircledIcon } from '../../reactcomponents/Form'
import SMTPGmailOAuth2 from '../../reactcomponents/Google'
import Input from '../../reactcomponents/Input'
import {
  OAuth2SMTP,
  useServerPortChange,
  withAdminConsent
} from '../../reactcomponents/Microsoft'
import {
  CELL_ACTION,
  CELL_BOOL,
  Sort,
  useExternalSort,
  withExpandedRow
} from '../../reactcomponents/Table'
import withSmtp, { withAWSSESIdentities } from '../../containers/smtp'
import {
  StyledListContentWrapper,
  SpacedEditorFooter,
  useDisableSave
} from '../receiptgreetings/list'
import {
  Div,
  actionEditAndDelete,
  useInvalidEmptyDataField,
  useOnEditField
} from '../receiptgreetings/receive'
import {
  BoolYesNoSelect,
  CommonList,
  OrgsAreasSelection,
  orgsAreasColumn,
  useNoOrgOrAreaSelection
} from '../receiptgreetings/close'

import { AdminAccountSideMenu } from "../v5/accounts/account"
const actionColumn = {
  Header: TXT_ACTION,
  accessor: 'id',
  actions: actionEditAndDelete,
  disableActionsWhenRowActive: actionEditAndDelete,
  sortName: 'id',
  type: CELL_ACTION
}
const hostColumn = { Header: TXT_SERVER, accessor: 'host' }
const usernameColumn = { Header: TXT_USERNAME, accessor: 'username' }
const columns = [
  hostColumn,
  usernameColumn,
  orgsAreasColumn,
  actionColumn
]
const sortColumns = [
  actionColumn,
  hostColumn,
  usernameColumn,
  orgsAreasColumn
]

const ListTable = composeWithDisplayName(
  'List',
  memo,
  withProps({ columns })
)(CommonList)

const getIntId = (which, id) => {
  if (id === 'create') {
    return { which, id, isNew: true }
  }
  return { which, id: parseInt(id, 10) }
}

const useList = ({ onDelete, onEditStart, onFinishEdit }) => {
  const { id } = useParams()
  const edit = useMemo(() => {
    if (typeof id === 'undefined') {
      return false
    }
    return getIntId(SMTP, id)
  }, [id])
  const history = useHistory()
  const onAdd = useCallback(
    e => { history.push(toEditPath(pathParams('create'))) },
    [history]
  )
  const onRowEdit = useCallback(
    (_, id) => { history.push(toEditPath(pathParams(id))) },
    [history]
  )
  const onEditClose = useCallback(
    () => { history.push(toListPath()) },
    [history]
  )
  const onDeleteAndCloseEdit = useIgnorePromiseCatch(useCallback(
    (...args) => onDelete(...args).then(() => { onEditClose() }),
    [onDelete, onEditClose]
  ))
  useEffect(() => {
    if (edit) {
      onEditStart(edit)
    }
    return () => {
      // must clean up the data as 'data' field share by two different
      // data-format component processor.
      onFinishEdit()
    }
  }, [edit, onEditStart, onFinishEdit])
  return {
    edit,
    onAdd,
    onDeleteAndCloseEdit,
    onEditClose,
    onRowEdit
  }
}

const titleMap = { [SMTP]: TXT_SMTP }

const EditFormHeader = withProps({ titleMap })(StandardEditorHeader)

const useDataInvalidation = ({ areas, default: def, host, orgs }) => {
  const emptySelection = useNoOrgOrAreaSelection(orgs, areas)
  const emptyServer = useInvalidEmptyDataField(TXT_SERVER, host)
  return useMemo(
    () => {
      const result = []
      if (emptyServer) {
        result.push(emptyServer)
      }
      if (!def && emptySelection) {
        result.push(emptySelection)
      }
      return result.join('. ')
    },
    [def, emptySelection, emptyServer]
  )
}

const Password = ({ disabled, onChange, password }) => (
  <Input
    autocomplete='false'
    disabled={disabled}
    onChange={useOnEditField(onChange, 'password')}
    type='password'
    value={password}
  />
)

const tokenFields = [
  'access_token',
  'expiry',
  'provider',
  'refresh_token',
  'token_type'
]

const useTokenChangeWithMerger = onChange => useCallback(
  data => {
    const token = {}
    each(tokenFields, field => { token[field] = data[field] })
    onChange(token)
  }, [onChange]
)

const useTokenChange = onChange => useTokenChangeWithMerger(useCallbackMultiValues(onChange, '$merge', AEO_MANUAL))

const useClearToken = onChange => {
  const changeTokenId = useOnEditField(onChange, 'oauth2tokenid')
  const unset = useCallbackMultiValues(onChange, '$unset', AEO_MANUAL)
  return useCallback(
    () => {
      unset(tokenFields)
      changeTokenId(0)
    },
    [changeTokenId, unset]
  )
}

const useIntToString = (state, onChange) => [
  typeof state === 'undefined' ? '' : state + '',
  useCallback(
    n => {
      if (typeof n !== 'number') {
        if (n) {
          n = parseInt(n, 10)
        } else {
          n = 0
        }
      }
      onChange(n)
    },
    [onChange]
  )
]

const MicrosoftOAuth2 = withAdminConsent(OAuth2SMTP)

const DefaultAuth = () => false //nothing to render here, as this will use the forms fields

const authComponents = {
  [UNSELECT]: DefaultAuth,
  [AUTH_MICROSOFT_OAUTH2]: MicrosoftOAuth2,
  [AUTH_GOOGLE_OAUTH2]: SMTPGmailOAuth2,
  [AUTH_BASIC]: DefaultAuth
}

const useAWSSESIdentities = ({
  data,
  identities: { allIds },
  original,
  onDelete,
  onEdit,
  onEditStart,
  onFinishEdit,
  onLoad,
  onPopAlert,
  onReload,
  onSave,
  regionId,
  smtpId
}) => {
  const onChange = useCallbackWithValue(regionId, onEdit)
  const setIdentity = useOnEditField(onChange, 'id')
  const setMailFromDomain = useOnEditField(onChange, 'mailFromDomain')
  const { identities: _identities } = useParams()
  const identities = typeof _identities === 'undefined' ? undefined : decodeURIComponent(_identities)
  const selected = useMemo(
    () => ({
      which: regionId,
      id: identities,
      isNew: typeof identities === 'undefined'
    }),
    [identities, regionId]
  )
  const { id, mailFromDomain } = data
  const idChanged = id && identities && id !== identities
  const editOfSave = useMemo(
    () => {
      const { which, id, isNew } = selected
      return { which, id, isNew: isNew || idChanged }
    },
    [idChanged, selected]
  )
  const dataForServer = useMemo(
    () => ({ id, mailFromDomain }),
    [id, mailFromDomain]
  )
  const [disabledAdd, onAdd] = useChangeDetectSave(simpleChange, {
    data: dataForServer,
    edit: editOfSave,
    onSave,
    original: idChanged ? initAWSSES : original
  })
  const history = useHistory()
  const [onCollapse, onExpand] = useMemo(
    () => [
      () => { history.push(toSelectAWSSESIdentity(smtpId, regionId)) },
      (_, id) => { history.push(toSelectAWSSESIdentity(smtpId, regionId, id)) }
    ],
    [history, regionId, smtpId]
  )
  const remove = useCallback(
    (region, id) => {
      if (id === identities) {
        onCollapse()
      }
      return onDelete(region, id)
    },
    [identities, onCollapse, onDelete]
  )
  useEffect(() => {
    onEditStart(selected)
    return () => {
      onFinishEdit()
    }
  }, [selected, onCollapse, onEditStart, onFinishEdit])
  useEffect(() => { onLoad(regionId) }, [onLoad, regionId])
  return {
    disabledAdd,
    expanded: useMemo(
      () => {
        if (identities) {
          let found
          each(allIds, (v, index) => {
            if (v === identities) {
              found = { index }
              return false
            }
          })
          if (found) {
            return { [found.index]: true }
          }
        }
        return emptyObject
      },
      [allIds, identities]
    ),
    identity: id,
    mailFromDomain,
    onAdd,
    onCollapse,
    onExpand,
    onReload: useCallbackWithValue(regionId, onReload),
    onShrinkThenDelete: useIgnorePromiseCatch(remove),
    setIdentity,
    setMailFromDomain
  }
}

const displayAWSSESRegion = ({ region }) => region

const SelectAWSSES = ({ dataAWSSES, disabled, onChange, value }) => (
  <ActiveBorderHighlightNormSingleSelect
    data={dataAWSSES}
    disabled={disabled}
    onSelect={onChange}
    selected={value}
    valueGetter={displayAWSSESRegion}
  />
)

const InputServer = ({ disabled, onChange, value }) => (
  <Input disabled={disabled} onChange={onChange} value={value} />
)

const InputOrSelectServer = branch(
  ({ isAWSSES }) => isAWSSES,
  renderComponent(SelectAWSSES)
)(InputServer)

const ServerBase = props => (
  <DoubleDecks text={TXT_SERVER}>
    <InputOrSelectServer {...props} />
  </DoubleDecks>
)

const withAWSSES = Component => ({
  dataAWSSES,
  disabled,
  isAWSSES,
  onChange,
  onSelect,
  value
}) => (
  // eslint-disable-next-line react/jsx-fragments
  <Fragment>
    <div className='left'>
      <Component
        dataAWSSES={dataAWSSES}
        disabled={disabled}
        isAWSSES={isAWSSES}
        onChange={onChange}
        value={value}
      />
    </div>
    <div className='right'>
      <DoubleDecks text={TXT_USE_AWS_SES}>
        <BoolYesNoSelect
          disabled={disabled}
          onSelect={onSelect}
          selected={isAWSSES}
        />
      </DoubleDecks>
    </div>
  </Fragment>
)

const Server = composeWithDisplayName(
  'Server',
  branch(
    () => true,
    withAWSSES,
    omitProps(['onSelect'])
  )
)(ServerBase)

const awssesInfo = ({ byId }, host) => byId[host]

const getRegionId = (awsses, host) => {
  const info = awssesInfo(awsses, host)
  if (info) {
    return info.regionId
  }
}

const checkHostIsAWSSES = (awsses, host) => {
  return typeof awssesInfo(awsses, host) !== 'undefined'
}

const renamePortPropsForSelectPort = {
  onChange: 'onSelect',
  portValue: 'selected'
}

const SelectPort = composeWithDisplayName(
  'SelectPort',
  omitProps(['value']),
  renameProps(renamePortPropsForSelectPort)
)(PlainArraySingleSelect)

const InputPort = ({ disabled, onChange, value }) => (
  <Input
    disabled={disabled}
    min={1}
    max={65535}
    onChange={onChange}
    type='number'
    value={value}
  />
)

const Port = branch(
  ({ isAWSSES }) => isAWSSES,
  renderComponent(SelectPort)
)(InputPort)

const ActiveCircleIcon = props => <CircledIcon active {...props} />

const ReloadIcon = ({ className, ...props }) => (
  <ActiveCircleIcon
    className={classNames(className, 'icon-reload')}
    title={TXT_RELOAD_DB}
    {...props}
  />
)

const StyledReloadIconBase = styled(ReloadIcon)`
  &::before {
    background-color: ${centionYellow}4f;
  }
`
const StyledReloadIcon = withUnmountWhenHidden(StyledReloadIconBase)

const StyledDiv = styled.div`${flexbox};`

const identityColumn = { Header: TXT_IDENTITY, accessor: 'id' }
const verifiedColumn = {
  Header: TXT_VERIFIED,
  accessor: 'verified',
  isIcon: true,
  type: CELL_BOOL
}
const awssesActions = [ACTION_COLLAPSE_EXPAND, ACTION_RELOAD, ACTION_DELETE]
const identitiesActionColumn = {
  Header: TXT_ACTION,
  id: 'action',
  accessor: 'id',
  actions: awssesActions,
  disableActionsWhenRowActive: awssesActions,
  propsGetter: (action, { row: { isExpanded } }) => {
    if (action === ACTION_COLLAPSE_EXPAND) {
      return { expanded: !!isExpanded }
    }
  },
  type: CELL_ACTION
}
const identitiesColumns = [
  identityColumn,
  verifiedColumn,
  identitiesActionColumn
]
const Identities = composeWithDisplayName(
  'Identities',
  memo,
  createWithMountCondition(({ list }) => list.length)
)(CommonList)

const IdentityDetailTableBase = props => (
  <AWSSESIdentityDetailTable awssesDKIMDomain={DKIM_AWS_SES} {...props} />
)

const IdentityDetailTable = withExpandedRow(IdentityDetailTableBase)

const StyledDetailTable = styled(IdentityDetailTable)`
  & {
    .table {
      margin-bottom: 0px;
    }
  }
`
const DomainOrIdentity = ({
  disabled,
  expandedIdentity,
  onFetchIdentity,
  userIsRoot,
  ...props
}) => {
  const { identities: { byId, allIds }, regionId } = props
  const {
    disabledAdd,
    expanded,
    identity,
    mailFromDomain,
    onAdd,
    onCollapse,
    onExpand,
    onReload,
    onShrinkThenDelete,
    setIdentity,
    setMailFromDomain
  } = useAWSSESIdentities(props)
  const identityExist = identity && typeof byId[identity] !== 'undefined'
  const actionMap = useMemo(
    () => ({
      [ACTION_DELETE]: onShrinkThenDelete,
      [ACTION_RELOAD]: onFetchIdentity,
      [ACTION_COLLAPSE]: onCollapse,
      [ACTION_EXPAND]: onExpand
    }),
    [onCollapse, onExpand, onFetchIdentity, onShrinkThenDelete]
  )
  const handleActions = useCallback(
    (action, id, ...args) => actionMap[action](regionId, id, ...args),
    [actionMap, regionId]
  )
  return (
    // eslint-disable-next-line react/jsx-fragments
    <Fragment>
      <Div>
        <div className='left'>
          <DoubleDecks text={TXT_IDENTITY_DOMAIN_EMAIL}>
            <Input
              disabled={disabled}
              onChange={setIdentity}
              value={identity}
            />
          </DoubleDecks>
        </div>
        <StyledDiv className='right'>
          <DoubleDecks text={TXT_MAIL_FROM_DOMAIN}>
            <Input
              disabled={disabled}
              onChange={setMailFromDomain}
              value={mailFromDomain}
            />
          </DoubleDecks>
        </StyledDiv>
      </Div>
      <StyledDiv>
        <ActiveCircleIcon
          className={identityExist ? 'icon-download' : 'icon-add'}
          disabled={disabled || (identityExist && disabledAdd)}
          onClick={onAdd}
          title={identityExist ? TXT_SAVE : TXT_ADD_NEW}
        />
        <StyledReloadIcon
          hidden={false && !userIsRoot}
          onClick={onReload}
        />
      </StyledDiv>
      <Identities
        SubComponent={StyledDetailTable}
        byId={byId}
        columns={identitiesColumns}
        expanded={expanded}
        list={allIds}
        onClickAction={handleActions}
        subComponentProps={useMemo(
          () => ({ colSpan: identitiesColumns.length, data: expandedIdentity }),
          [expandedIdentity]
        )}
      />
    </Fragment>
  )
}

const DomainOrIdentityCtnr = composeWithDisplayName(
  'DomainOrIdentityCtnr',
  withAWSSESIdentities,
  withProps(({ editValue }) => {
    if (editValue) {
      return { data: editValue.data, original: editValue.original }
    }
    // TODO: the logic flow not ideal here as init value is populated by
    // useEffect DomainOrIdentity which itself also require work-able init value
    // to render. It becomes a problem when refactor local state to redux-store.
    // To proper solve this need to move out the useEffect or just make sure
    // init data format correct as what is done here.
    return initEditValue
  })
)(DomainOrIdentity)

const FlexDiv = styled(Div)`
  ${flexbox};
  > * {
    width: 100%;
  }
`

const Edit = ({
  awsses,
  byRegionIdAWSSES,
  data: {
    // eslint-disable-next-line camelcase
    access_token,
    active,
    areas,
    default: def,
    oauth2tokenid,
    provider,
    host,
    id,
    orgs,
    password,
    port,
    secure,
    username,
    sesapi,
  },
  isNew,
  oauthConstants,
  onChange,
  onPopAlert,
  orgArea,
  userIsRoot
}) => {
  const isFileSmtp = id < 0
  const canCreateDefault = cflag.IsActive('2020-01-08.webd.in.the.cloud') && userIsRoot
  const defNeedRoot = def && !userIsRoot && ONLY_ROOT_EDIT_DEF
  const showDefault = isFileSmtp || def || canCreateDefault
  // eslint-disable-next-line camelcase
  const hasOAuth2Token = oauth2tokenid > 0 || access_token
  const hostIsAWSSES = checkHostIsAWSSES(awsses, host)
  const handleServerChange = useOnEditField(onChange, 'host')
  const handleUsernameChange = useOnEditField(onChange, 'username')
  const handleSESAPIChange = useOnEditField(onChange, 'sesapi')
  const [
    portString,
    setPortString
  ] = useIntToString(port, useOnEditField(onChange, 'port'))
  const history = useHistory()
  const regionId = getRegionId(awsses, host)
  const [enableAWSSES, setEnableAWSSES] = useState(hostIsAWSSES)
  const [auth, setAuth] = useState(UNSELECT)
  const handleSelectAuth = (v) => {
    if(v === AUTH_MICROSOFT_OAUTH2 || v === AUTH_GOOGLE_OAUTH2) {
      // clean/reset back input if we change selection
      handleServerChange("");
    }
    setAuth(v)
  }
  const smtpId = isNew ? 'create' : id
  const handleSelectAWSSES = useCallback(selected => {
    setEnableAWSSES(selected)
    if (selected) {
      if (regionId) {
        history.push(toSelectAWSSESIdentity(smtpId, regionId))
      }
    } else {
      history.push(toEditPath(pathParams(smtpId)))
      handleServerChange('')
    }
  }, [handleServerChange, history, smtpId, regionId])
  const serverChangeOrSelect = useCallback(server => {
    if (enableAWSSES) {
      history.push(toSelectAWSSESIdentity(smtpId, getRegionId(awsses, server)))
    } else {
      if (checkHostIsAWSSES(awsses, server)) {
        history.push(toSelectAWSSESIdentity(
          smtpId,
          getRegionId(awsses, server)
        ))
      }
      handleServerChange(server)
    }
  }, [awsses, handleServerChange, history, smtpId, enableAWSSES])
  const region = useRegionParam()
  const newAndHasRegionParam = isNew && region
  const invalidRegion = region && typeof byRegionIdAWSSES[region] === 'undefined'
  useEffect(() => {
    if (hostIsAWSSES || newAndHasRegionParam) {
      setEnableAWSSES(true)
    }
  }, [hostIsAWSSES, newAndHasRegionParam])
  useEffect(() => {
    if (invalidRegion) {
      history.push(toEditPath(pathParams(smtpId)))
    }
  }, [history, invalidRegion, smtpId])
  useEffect(() => {
    if (hostIsAWSSES) {
      history.push(toSelectAWSSESIdentity(smtpId, regionId))
    }
    // this initial sync only happen at edit start by URL input
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (enableAWSSES) {
      let smtp
      if (region) {
        smtp = byRegionIdAWSSES[region]
      }
      if (!smtp) {
        smtp = ''
      }
      handleServerChange(smtp)
    }
  }, [byRegionIdAWSSES, enableAWSSES, handleServerChange, region])

  let hideUsernamePassword = false;
  if(auth === AUTH_MICROSOFT_OAUTH2 || auth === AUTH_GOOGLE_OAUTH2) {
    hideUsernamePassword = true;
  }

  return (
    // eslint-disable-next-line react/jsx-fragments
    <Fragment>
      <Div hidden={auth === AUTH_MICROSOFT_OAUTH2 || auth === AUTH_GOOGLE_OAUTH2}>
        <Server
          dataAWSSES={awsses}
          disabled={defNeedRoot}
          isAWSSES={enableAWSSES}
          onChange={serverChangeOrSelect}
          onSelect={handleSelectAWSSES}
          value={host}
        />
      </Div>
      {/*no longer needed 'username' & 'password' as user login with consent from Microsoft & Google.. unless new addition*/}
      <Div hidden={enableAWSSES || hideUsernamePassword}>
        <div className='left'>
          <DoubleDecks text={TXT_USERNAME}>
            <Input
              disabled={defNeedRoot}
              onChange={handleUsernameChange}
              value={username}
            />
          </DoubleDecks>
        </div>
        <div className='right'>
          <DoubleDecks text={TXT_PASSWORD}>
            <Password
              disabled={defNeedRoot}
              onChange={onChange}
              password={password}
            />
          </DoubleDecks>
        </div>
      </Div>
      <FlexDiv direction='column' hidden={!enableAWSSES || !hostIsAWSSES}>
        <DomainOrIdentityCtnr
          regionId={regionId}
          smtpId={smtpId}
          userIsRoot={userIsRoot}
        />
      </FlexDiv>
      {/*<Div hidden={!enableAWSSES}>
        <div className='left'>
          <DoubleDecks
            text={TXT_USE_AWS_SES_API}
            helper={<div>{TXT_HELPER_AWS_SES_API}</div>}
          >
            <BoolYesNoSelect
              onSelect={handleSESAPIChange}
              selected={sesapi}
            />
          </DoubleDecks>
        </div>
      </Div>*/}
      <Div>
        <div className='left'>
          <DoubleDecks text={TXT_PORT}>
            <Port
              data={awsses.ports}
              isAWSSES={enableAWSSES}
              onChange={setPortString}
              portValue={port}
              value={portString}
            />
          </DoubleDecks>
        </div>
        {/*"Secure" field set hidden="true" until further notice*/}
        {/* <HideableDiv className='right' hidden={enableAWSSES}> */}
        <HideableDiv className='right' hidden={true}>
          <DoubleDecks text={TXT_SECURE}>
            <BoolYesNoSelect
              disabled={defNeedRoot}
              onSelect={useOnEditField(onChange, 'secure')}
              selected={secure}
            />
          </DoubleDecks>
        </HideableDiv>
      </Div>
      <Div hidden={enableAWSSES}>
        <div className='left'>
          <DoubleDecks text={TXT_AUTHORIZATION}>
            <AuthorizationSelect
              components={authComponents}
              constants={oauthConstants}
              disabled={defNeedRoot}
              onChangeServerPort={useServerPortChange(
                host,
                port,
                handleServerChange,
                setPortString
              )}
              onChangeToken={useTokenChange(onChange)}
              onChangeUsername={handleUsernameChange}
              onPopAlert={onPopAlert}
              option={smtpAuthorizationOption}
              selected={auth}
              onSelect={handleSelectAuth}
              // eslint-disable-next-line camelcase
              provider={justAuthorizedTokenProvider(access_token, provider)}
            />
          </DoubleDecks>
        </div>
        <div hidden={auth === AUTH_BASIC} className='right'>
          <DoubleDecks text={TXT_AUTHORIZED_ACCESS_TOKEN}>
            <AuthorizedToken
              disabled={defNeedRoot}
              hasAccessToken={hasOAuth2Token}
              onClear={useClearToken(onChange)}
              provider={provider}
            />
          </DoubleDecks>
        </div>
      </Div>
      <Div>
        <HideableDiv className='left' hidden={!showDefault}>
          <DoubleDecks text={TXT_DEFAULT}>
            <BoolYesNoSelect
              disabled={isFileSmtp || !canCreateDefault}
              onSelect={useOnEditField(onChange, 'default')}
              selected={def}
            />
          </DoubleDecks>
        </HideableDiv>
        <OrgsAreasSelection
          areas={areas}
          hidden={isFileSmtp}
          onChange={onChange}
          orgArea={orgArea}
          orgs={orgs}
          toRight={showDefault}
        />
      </Div>
    </Fragment>
  )
}

const EditFormBase = ({
  awsses,
  byRegionIdAWSSES,
  oauthConstants,
  onCancel,
  onChange,
  onDelete,
  onLoad,
  onPopAlert,
  orgArea,
  userIsRoot,
  ...props
}) => {
  const { data, edit: { id, isNew, which } } = props
  const [noChange, handler] = useChangeDetectSave(receiptGreetingChange, props)
  return (
    <EditorBox>
      <Edit
        awsses={awsses}
        byRegionIdAWSSES={byRegionIdAWSSES}
        data={data}
        isNew={isNew}
        oauthConstants={oauthConstants}
        onChange={useCallbackWithValue(which, onChange)}
        onPopAlert={onPopAlert}
        orgArea={orgArea}
        userIsRoot={userIsRoot}
      />
      <SpacedEditorFooter
        disableSave={useDisableSave(isNew, noChange, useDataInvalidation(data))}
        hideDelete={isNew}
        onCancel={onCancel}
        onDelete={useCallbackMultiValues(onDelete, which, id)}
        onSave={handler}
      />
    </EditorBox>
  )
}

const EditForm = composeWithDisplayName(
  'EditForm',
  memo,
  withProps(({ data, edit }) => {
    if (!edit || !data || edit.which !== data.which) {
      return { hidden: true }
    }
    return { data: data.data, original: data.original }
  }),
  withUnmountWhenHidden
)(EditFormBase)

const ListBase = ({
  awsses,
  byRegionIdAWSSES,
  className,
  data: { byId, allIds },
  editData,
  oauthConstants,
  onActivate,
  onEdit,
  onLoad,
  onPopAlert,
  onSave,
  orgArea,
  servicesAndAccounts,
  userIsRoot,
  ...props
}) => {
  const {
    edit,
    onAdd,
    onDeleteAndCloseEdit,
    onEditClose,
    onRowEdit
  } = useList(props)
  const onDelete = useIgnorePromiseCatch(props.onDelete)
  const actionMap = useMemo(
    () => ({ [ACTION_DELETE]: onDelete, [ACTION_EDIT]: onRowEdit }),
    [onDelete, onRowEdit]
  )
  const handleActions = useCallback(
    (action, id, ...args) => actionMap[action](SMTP, id, ...args),
    [actionMap]
  )
  const activeChecker = useCallback(({ original: { id } }) => {
    if (edit) {
      return isNumberOrNumberStringEqual(edit.id, id)
    }
  }, [edit])
  const { sortBy, onChange, onSortChange } = useExternalSort(emptyArray)
  useEffect(() => { onLoad() }, [onLoad])

  const handleChangeView = useCallback((url, label, id) => {
    props.onChangeView(id, url);
  }, [])

  let accountSideMenu = <AdminAccountSideMenu
    view={props.view}
    item={props.subMenus}
    onClick={handleChangeView}
  />;
  return (
    <Skeleton
      className={className}
      lists={
        // eslint-disable-next-line react/jsx-fragments
        <Fragment>
          <ListHeaderWrapper>
            <TableHeader addActive={!!edit} onClickAdd={onAdd} title={TXT_SMTP}>
              <Sort columns={sortColumns} onChange={onChange} sortBy={sortBy} />
            </TableHeader>
          </ListHeaderWrapper>
          <StyledListContentWrapper>
            <ListTable
              activeChecker={activeChecker}
              byId={byId}
              list={allIds}
              onClickAction={handleActions}
              onSortChange={onSortChange}
              orgArea={orgArea}
              sortBy={sortBy}
            />
          </StyledListContentWrapper>
        </Fragment>
      }
      extraComponent={accountSideMenu}
    >
      <EditFormHeader edit={edit} onClose={onEditClose} />
      <EditForm
        awsses={awsses}
        byRegionIdAWSSES={byRegionIdAWSSES}
        data={editData}
        edit={edit}
        oauthConstants={oauthConstants}
        onCancel={onEditClose}
        onChange={onEdit}
        onDelete={onDeleteAndCloseEdit}
        onPopAlert={onPopAlert}
        onSave={onSave}
        orgArea={orgArea}
        userIsRoot={userIsRoot}
      />
    </Skeleton>
  )
}

const List = withSmtp(ListBase)

export default List
