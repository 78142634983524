import React, { Fragment, memo, useCallback, useEffect, useMemo } from 'react'
import { withProps } from 'recompose'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components'
import {
  CLOSE_RECEIPTS,
  FEEDBACKS,
  GREETINGS,
  RECEIVE_RECEIPTS
} from '../../common/path'
import { emptyArray } from '../../common/constants'
import {
  isNumberOrNumberStringEqual,
  receiptGreetingChange
} from '../../common/helpers'
import {
  TXT_CLOSE_RECEIPT,
  TXT_FEEDBACK,
  TXT_GREETING,
  TXT_RECEIPT_GREETINGS,
  TXT_RECEIPT_RECEIVE,
  pathParams,
  titleMap,
  toEditPath,
  toSelectPath
} from '../../common/v5/receiptGreetingConstants'
import {
  useCallbackWithValue,
  useIgnorePromiseCatch
} from '../../hooks/callback'
import {
  composeWithDisplayName,
  withUnmountWhenHidden
} from '../../reactcomponents/hocs'
import {
  ACTION_ACTIVATE,
  ACTION_DELETE,
  ACTION_EDIT,
  CancelButton,
  EditorBox,
  EditorFooter,
  LabeledDelete,
  ListContentWrapper,
  ListHeaderWrapper,
  SaveButton,
  StandardEditorHeader,
  TableHeader,
  Skeleton
} from '../../reactcomponents/Admin'
import { useChangeDetectSave } from '../../reactcomponents/Chatbot'
import NavTabs from '../../reactcomponents/NavTabs'
import { Sort, useExternalSort } from '../../reactcomponents/Table'
import withReceiptGreetings from '../../containers/receiptGreeting'
import ListClose, {
  Edit as EditClose,
  columns as closeColumns,
  dataInvalidation as closeReceiptsDataInvalidation,
  useDataInvalidation as useCloseReceiptsDataInvalidator
} from './close'
import ListFeedback, {
  Edit as EditFeedback,
  columns as feedbackColumns,
  dataInvalidation as feedbacksDataInvalidation,
  useDataInvalidation as useFeedbacksDataInvalidator
} from './feedback'
import ListGreeting, {
  Edit as EditGreeting,
  columns as greetingColumns,
  dataInvalidation as greetingsDataInvalidation,
  useDataInvalidation as useGreetingsDataInvalidator
} from './greeting'
import ListReceive, {
  Edit as EditReceive,
  columns as receiveColumns,
  dataInvalidation as receiveReceiptsDataInvalidation,
  useDataInvalidation as useReceiveReceiptsDataInvalidator
} from './receive'
import { BTN_TXT_SAVE, BTN_TXT_SAVING } from '../../common/v5/constants'

const getIntId = (which, id) => {
  if (id === 'create') {
    return { which, id, isNew: true }
  }
  return { which, id: parseInt(id, 10) }
}

const useList = ({ onDelete, onEditStart, onFinishEdit }) => {
  const { 0: tab, 1: which, id } = useParams()
  const edit = useMemo(() => {
    if (typeof which === 'undefined') {
      return false
    }
    return getIntId(which, id)
  }, [which, id])
  const history = useHistory()
  const onTabChange = useCallback(tab => {
    if (edit) {
      const { id, which } = edit
      history.push(toEditPath(pathParams(tab, which, id)))
    } else {
      history.push(toSelectPath(pathParams(tab)))
    }
  }, [edit, history])
  const onAdd = useCallback(e => {
    history.push(toEditPath(pathParams(tab, tab, 'create')))
  }, [history, tab])
  const onRowEdit = useCallback((_, id) => {
    history.push(toEditPath(pathParams(tab, tab, id)))
  }, [history, tab])
  const onEditClose = useCallback(() => {
    history.push(toSelectPath(pathParams(tab)))
  }, [history, tab])
  const onDeleteAndCloseEdit = useIgnorePromiseCatch(useCallback(
    (...args) => onDelete(...args).then(() => { onEditClose() }),
    [onDelete, onEditClose]
  ))
  const onTypeChange = useCallback(type => {
    // receipt type can only be changed in new creation
    history.push(toEditPath(pathParams(tab, type, 'create')))
  }, [history, tab])
  useEffect(() => {
    if (edit) {
      onEditStart(edit)
    }
    return () => {
      // must clean up the data as 'data' field share by two different
      // data-format component processor.
      onFinishEdit()
    }
  }, [edit, onEditStart, onFinishEdit])
  return {
    edit,
    onAdd,
    onDeleteAndCloseEdit,
    onEditClose,
    onRowEdit,
    onTabChange,
    onTypeChange,
    tab
  }
}

const tableTabs = [
  {
    id: RECEIVE_RECEIPTS,
    name: TXT_RECEIPT_RECEIVE,
    Renderer: ListReceive
  },
  {
    id: CLOSE_RECEIPTS,
    name: TXT_CLOSE_RECEIPT,
    Renderer: ListClose
  },
  {
    id: FEEDBACKS,
    name: TXT_FEEDBACK,
    Renderer: ListFeedback
  },
  {
    id: GREETINGS,
    name: TXT_GREETING,
    Renderer: ListGreeting
  }
]

const TabTable = ({ onTabChange, tab, ...props }) => (
  <NavTabs
    activeTab={tab}
    onClick={onTabChange}
    tabs={tableTabs}
    {...props}
  />
)

export const StyledListContentWrapper = styled(ListContentWrapper)`
  &.table-wrapper {
    margin-left: unset;
    .table {
      width: 100%;
    }
  }
`
const EditFormHeader = withProps({ titleMap })(StandardEditorHeader)

export const useDisableSave = (isNew, noChange, invalidData) => useMemo(
  () => {
    const result = []
    if (!isNew && noChange) {
      result.push(noChange)
    }
    if (invalidData) {
      result.push(invalidData)
    }
    return result.join('. ')
  },
  [isNew, noChange, invalidData]
)

const SpacingDiv = styled.div`
  margin: 10px 0px;
`
export const SpacedEditorFooter = ({
  disableSave,
  hideDelete,
  onCancel,
  onDelete,
  onSave,
  saveTxt = BTN_TXT_SAVE
}) => {
  const handleSaveClick = (e) => {
    if (window.confirm('WARNING: Are you sure you want to save changes?')) {
      onSave(e);
    }
  };

  return (
    <SpacingDiv>
      <EditorFooter
        left={<LabeledDelete hidden={hideDelete} onClick={onDelete} />}
      >
        <CancelButton onClick={onCancel} />
        <SaveButton disabled={disableSave} onClick={handleSaveClick} text={saveTxt} />
      </EditorFooter>
    </SpacingDiv>
  );
};


const EditFormBase = ({
  component: Component,
  invalidData,
  onCancel,
  onChange,
  onDelete,
  onPopAlert,
  onTypeChange,
  orgArea,
  servicesAndAccounts,
  services,
  adminStatus,
  ...props
}) => {
  const { data, edit: { id, isNew, which } } = props
  const [noChange, handler] = useChangeDetectSave(receiptGreetingChange, props)
  let buttonSaveTxt = BTN_TXT_SAVE;
  if(adminStatus && adminStatus.status === 1) {
    buttonSaveTxt = BTN_TXT_SAVING;
  }
  return (
    <EditorBox>
      <Component
        data={data}
        isNew={isNew}
        onChange={useCallbackWithValue(which, onChange)}
        onPopAlert={onPopAlert}
        onTypeChange={onTypeChange}
        orgArea={orgArea}
        servicesAndAccounts={servicesAndAccounts}
        services={services}
      />
      <SpacedEditorFooter
        disableSave={useDisableSave(isNew, noChange, invalidData)}
        hideDelete={isNew}
        onCancel={onCancel}
        onDelete={useCallbackWithValue(id, onDelete)}
        onSave={handler}
        saveTxt={buttonSaveTxt}
      />
    </EditorBox>
  )
}

const EditFormReceiveReceipts = props => (
  <EditFormBase
    invalidData={useReceiveReceiptsDataInvalidator(props.data)}
    {...props}
  />
)

const EditFormCloseReceipts = props => (
  <EditFormBase
    invalidData={useCloseReceiptsDataInvalidator(props.data)}
    {...props}
  />
)

const EditFormFeedbacks = props => (
  <EditFormBase
    invalidData={useFeedbacksDataInvalidator(props.data)}
    {...props}
  />
)

const EditFormGreetings = props => (
  <EditFormBase
    invalidData={useGreetingsDataInvalidator(props.data)}
    {...props}
  />
)

const editFormMap = {
  [CLOSE_RECEIPTS]: EditFormCloseReceipts,
  [FEEDBACKS]: EditFormFeedbacks,
  [GREETINGS]: EditFormGreetings,
  [RECEIVE_RECEIPTS]: EditFormReceiveReceipts
}

const RenderEditForm = ({ editForm: EditForm, onDelete, ...props }) => (
  <EditForm
    onDelete={useCallbackWithValue(props.edit.which, onDelete)}
    {...props}
  />
)

const editorMap = {
  [CLOSE_RECEIPTS]: EditClose,
  [FEEDBACKS]: EditFeedback,
  [GREETINGS]: EditGreeting,
  [RECEIVE_RECEIPTS]: EditReceive
}

const columnsMap = {
  [CLOSE_RECEIPTS]: closeColumns,
  [FEEDBACKS]: feedbackColumns,
  [GREETINGS]: greetingColumns,
  [RECEIVE_RECEIPTS]: receiveColumns
}

const EditForm = composeWithDisplayName(
  'EditForm',
  memo,
  withProps(({ data, edit }) => {
    if (!edit || !data || edit.which !== data.which) {
      return { hidden: true }
    }
    const { which } = edit
    return {
      component: editorMap[which],
      editForm: editFormMap[which],
      data: data.data,
      original: data.original
    }
  }),
  withUnmountWhenHidden
)(RenderEditForm)

const hiddenDatabaseIdColumn = ['id']

const dataInvalidationMap = {
  [CLOSE_RECEIPTS]: closeReceiptsDataInvalidation,
  [FEEDBACKS]: feedbacksDataInvalidation,
  [GREETINGS]: greetingsDataInvalidation,
  [RECEIVE_RECEIPTS]: receiveReceiptsDataInvalidation
}

const ListBase = ({
  className,
  data: { byId, allIds },
  editData,
  onActivate,
  onEdit,
  onLoad,
  onPopAlert,
  onSave,
  orgArea,
  servicesAndAccounts,
  adminStatus,
  ...props
}) => {
  const {
    edit,
    onAdd,
    onDeleteAndCloseEdit,
    onEditClose,
    onRowEdit,
    onTabChange,
    onTypeChange,
    tab
  } = useList(props)
  const onDelete = useIgnorePromiseCatch(props.onDelete)
  const handleActivate = useCallback((which, id, active) => {
    if (!active) {
      const invalidData = dataInvalidationMap[which](byId[id])
      if (invalidData) {
        onPopAlert(invalidData)
        return
      }
    }
    onActivate(which, id, active)
  }, [byId, onActivate, onPopAlert])
  const actionMap = useMemo(
    () => ({
      [ACTION_ACTIVATE]: handleActivate,
      [ACTION_DELETE]: onDelete,
      [ACTION_EDIT]: onRowEdit
    }),
    [handleActivate, onDelete, onRowEdit]
  )
  const handleActions = useCallback(
    (action, id, ...args) => actionMap[action](tab, id, ...args),
    [actionMap, tab]
  )
  const activeChecker = useCallback(({ original: { id } }) => {
    if (edit) {
      const { which, id: editId } = edit
      return which === tab && isNumberOrNumberStringEqual(editId, id)
    }
  }, [edit, tab])
  const { sortBy, onChange, onSortChange } = useExternalSort(emptyArray)
  useEffect(() => {
    onLoad()
  }, [onLoad])
  return (
    <Skeleton
      className={className}
      idClass={"AdminReceiptGreetings"}
      lists={
        // eslint-disable-next-line react/jsx-fragments
        <Fragment>
          <ListHeaderWrapper>
            <TableHeader
              addActive={!!edit}
              onClickAdd={onAdd}
              title={TXT_RECEIPT_GREETINGS}
            >
              <Sort
                columns={columnsMap[tab]}
                onChange={onChange}
                sortBy={sortBy}
              />
            </TableHeader>
          </ListHeaderWrapper>
          <StyledListContentWrapper>
            <TabTable
              activeChecker={activeChecker}
              byId={byId}
              hiddenColumns={hiddenDatabaseIdColumn}
              list={allIds}
              onClickAction={handleActions}
              onSortChange={onSortChange}
              onTabChange={onTabChange}
              orgArea={orgArea}
              servicesAndAccounts={servicesAndAccounts}
              sortBy={sortBy}
              tab={tab}
            />
          </StyledListContentWrapper>
        </Fragment>
      }
    >
      <EditFormHeader edit={edit} onClose={onEditClose} />
      <EditForm
        data={editData}
        edit={edit}
        onCancel={onEditClose}
        onChange={onEdit}
        onDelete={onDeleteAndCloseEdit}
        onPopAlert={onPopAlert}
        onSave={onSave}
        onTypeChange={onTypeChange}
        orgArea={orgArea}
        servicesAndAccounts={servicesAndAccounts}
        adminStatus={adminStatus}
      />
    </Skeleton>
  )
}

const List = withReceiptGreetings(ListBase)

export default List
