import React from 'react';
//dev component
import CHelper from '../../components/CHelper';
import DropDown from '../../components/DropDown';
import Calender from '../../components/Calender';
import {
	withAdvancedSearch,
	withMapExportPreview
} from './exportCtnr';
import {
	SerialMultiSelect
} from '../../reactcomponents/Dropdown';
import {
	ReadOnly,
	FormInputWithLabelRow,
	ReadOnlyInputRow,
	TextInputRow,
	SelectInputRow,
	CheckboxInputRow,
	AvatarInputRow
} from '../../reactcomponents/Form';
import Button from '../../reactcomponents/Button';
import { Channels } from '../../redux/constants/endpoints';
import { SingleCalenderDD } from '../../reactcomponents/DatePicker';
import { UNSELECT, INPUT_EMPTY_WARNING, MESSAGE_INVALID_EMAIL } from '../../common/v5/constants';
import {
	formatValueToNameObj,
	isValidEmail
} from '../../common/v5/helpers';
import {
	SelectionBox
} from '../../reactcomponents/SelectBox';
import { ButtonIcon } from '../../reactcomponents/HeaderActions'

var Include = [
	{'Id': 1, 'Name': I('All Errands')},
	{'Id': 2, 'Name': I('Search Errands')}
];
var TimeFrameType = [
    { 'Id': 0, 'Name': I('Relative') },
	{ 'Id': 1, 'Name': I('Absolute') }
];

export const SelectedReadOnly = ({title, selected = [], className, data = [], ...props}) => {
	let selectedList = [], matchSelected;
	$.each(selected , (i,v) => {
		if(data && data.length > 0){
			matchSelected = data.find(obj => obj.Id == v);
			if(matchSelected) {
				selectedList.push(<li key={i+matchSelected.Name}>{matchSelected.Name}</li>);
			}
		}
	});
	return (
		<div className={className} hidden={selected && selected.length > 0 ? false : true}>
			<span className="export-selected-title">{title} : </span>
			<ul>
				{selectedList}
			</ul>
		</div>
	);
};

class AdvancedSearchV5 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showPositiveTags: false,
			showNegativeTags: false
		}
	}
	componentWillMount = () => {}
	componentDidMount = () => {}
	stringToArray = (items) => {
		let a = []
		if(items != "") {
			a = items.split(",").map(function(x){
				return parseInt(x, 10);
			});
		}
		return a;
	}
	getTags = () => {
		var tags = [];
		this.props.tags.map(function(item, i) {
			if(item.Tags != null) {
				item.Tags.map(function(t, i){
					tags.push({'Id': t.id, 'Name': item.name + " - " + t.name });
				});
			}
		});
		return tags;
	}
	generateEntryRow = (flag, items) => {
		var list = [];
		if(items == null) {
			return null;
		}
		items.map(function(item, i) {
			var l = (
				<div className="remove-attach" key={flag+"_"+i}>
					<span id="selectItem" style={{paddingRight: '5px'}}>{item}</span>
					<i style={{cursor: 'pointer'}} onClick={(e) => this.handleRemoveSearchTerm(flag, item)} className="icon-circled-times"/>
			    </div>
			);
			list.push(l);
		}, this);
		return (
			<div className="form-group">
				<div className="col-lg-9 col-sm-9 col-xs-9" style={{"paddingLeft": "30px"}}>
					{list}
				</div>
				<div className="clear"></div>
			</div>
		);
	}
	valueExistInArray = (array, id) => {
		for(let i = 0 ; i< array.length; i++){
			if(array[i]== id){
				return true;
			}
		}
		return false;
	}
    renderCategoryCheckbox = (flag, items) => {
		var list = [];
		if(this.props.export.errandCategory == null) {
			return null;
		}
		this.props.export.errandCategory.map(function(c, i) {
			var l = (
				<div key={flag+"_"+i}>
					<input type="checkbox"
						key={"category_" + flag + c.id}
						checked={this.valueExistInArray(items, c.id)}
						value={c.id}
						onChange={(e) => this.handleErrandCategory(flag, e)}
					/>
					<span> {c.name}</span>
					<br />
				</div>
			);
			list.push(l);
		}, this);
		return (
			<div className="answer-list-wrapper">
				{list}
			</div>
		);
	}
	handleErrandCategory = (flag, e) => {
		if(e.target.checked == true) {
			this.props.onSetErrandCategory(flag, parseInt(e.target.value, 10));
		} else {
			this.props.onRemoveErrandCategory(flag, parseInt(e.target.value, 10));
		}
	}
	handlePositiveTags = items => {
		let a = this.stringToArray(items);
		this.props.onSetValue('positiveTags', a);
	}
	handleNegativeTags = items => {
		let a = this.stringToArray(items);
		this.props.onSetValue('negativeTags', a);
	}
	handleSavePsWords = () => {
		if(this.positiveWords.value != "") {
			this.props.onSetValue('positiveWords', this.positiveWords.value);
		}
		this.positiveWords.value = "";
	}
	handleSaveNgWords = () => {
		if(this.negativeWords.value != "") {
			this.props.onSetValue('negativeWords', this.negativeWords.value);
		}
		this.negativeWords.value = "";
	}
	handleSavePsFromAddress = () => {
		if(this.positiveFromAddress.value != "") {
			this.props.onSetValue('positiveFromAddress', this.positiveFromAddress.value);
		}
		this.positiveFromAddress.value = "";
	}
	handleSaveNgFromAddress = () => {
		if(this.negativeFromAddress.value != "") {
			this.props.onSetValue('negativeFromAddress', this.negativeFromAddress.value);
		}
		this.negativeFromAddress.value = "";
	}
	handleRemoveSearchTerm = (flag, item) => {
		this.props.onRemoveSearchTerm(flag, item);
	}
	handleTogglePositiveTags = () => {
		this.setState({
			showPositiveTags: !this.state.showPositiveTags
		})
	}
	handleToggleNegativeTags = () => {
		this.setState({
			showNegativeTags: !this.state.showNegativeTags
		})
	}
	render() {
		return (
			<div className="section advanced-export">
				{/* == Errands must have at least one of the following categories */}
				<div className="checkbox-questions">
					<div className="label-positive-category">
						<label className="col-lg-12 col-sm-6 col-xs-12 control-label" htmlFor="basic">
							{I("Errands must have at least one of the following categories")}
						</label>
					</div>
					{this.renderCategoryCheckbox("positiveCategory", this.props.export.positiveCategory)}
				</div>
				{/* == Errands must have none of the following categories */}
				<div className="checkbox-questions">
					<div className="label-negative-category">
						<label className="col-lg-12 col-sm-6 col-xs-12 control-label" htmlFor="basic">
							{I("Errands must have none of the following categories")}
						</label>
					</div>
					{this.renderCategoryCheckbox("negativeCategory", this.props.export.negativeCategory)}
				</div>
				{/* == Errand positive tags */}
				<FormInputWithLabelRow label={I("Errands must have at least one of the following tags")} className={"export-multiselect-dd"}>
					<SerialMultiSelect
						id={"positiveTags"}
						title={I("Please select tags")}
						className={"popup-multi-select"}
						overrideTitle={true}
						groupSelect={true}
						// nested={{key: 'Areas'}}
						data={this.getTags()}
						idFields={{id: "Id", name: "Name"}}
						selected={this.props.export.positiveTags}
						onSelect={this.handlePositiveTags}
						onToggle={this.handleTogglePositiveTags}
						show={this.state.showPositiveTags}
						selectAll={true}
						selectNone={true}
						// showChevron={true}
					/>
					<div style={{width: "100%", display: "inline-block"}}>
						<SelectedReadOnly title={I("Selected Tags")} selected={this.props.export.positiveTags} data={this.getTags()} className="export-selected-options" />
					</div>
				</FormInputWithLabelRow>
				{/* == Errand negative tags */}
				<FormInputWithLabelRow label={I("Errands must have none of the following tags")} className={"export-multiselect-dd"}>
					<SerialMultiSelect
						id={"negativeTags"}
						title={I("Please select tags to be discarded")}
						className={"popup-multi-select"}
						overrideTitle={true}
						groupSelect={true}
						// nested={{key: 'Areas'}}
						data={this.getTags()}
						idFields={{id: "Id", name: "Name"}}
						selected={this.props.export.negativeTags}
						onSelect={this.handleNegativeTags}
						onToggle={this.handleToggleNegativeTags}
						show={this.state.showNegativeTags}
						selectAll={true}
						selectNone={true}
						// showChevron={true}
					/>
					<div style={{width: "100%", display: "inline-block"}}>
						<SelectedReadOnly title={I("Selected Tags")} selected={this.props.export.negativeTags} data={this.getTags()} className="export-selected-options" />
					</div>
				</FormInputWithLabelRow>
				{/* == Errands must include at least one of these words */}
				<div className="label-row">
					<div className="label-block block-rows">
						<div className="label-export-input" style={{paddingRight: '0px'}}>
							<label className="" htmlFor="basic">
								{I("Errands must include at least one of these words")}
							</label>
						</div>
						<div className="export-input with-buttons">
							<input type="text" className='text-field export-input with-buttons'
								placeholder={I("Search Terms")}
								ref={(ref) => this.positiveWords = ref}
							/>
						</div>
						<div className="export-input button">
							<Button key="btn-run-export"
								color="blue"
								text={I("Add")}
								className="run-button"
								onClick={this.handleSavePsWords}
							/>
						</div>
					</div>
				</div>
				{this.generateEntryRow("positiveWords", this.props.export.positiveWords)}
				{/* == Errands must not include any of these words */}
				<div className="label-row">
					<div className="label-block block-rows">
						<div className="label-export-input" style={{paddingRight: '0px'}}>
							<label className="" htmlFor="basic">
								{I("Errands must not include any of these words")}
							</label>
						</div>
						<div className="export-input with-buttons">
							<input type="text" className='text-field export-input with-buttons'
								placeholder={I("Search Terms")}
								ref={(ref) => this.negativeWords = ref}
							/>
						</div>
						<div className="export-input button">
							<Button key="btn-run-export"
								color="blue"
								text={I("Add")}
								className="run-button"
								onClick={this.handleSaveNgWords}
							/>
						</div>
					</div>
				</div>
				{this.generateEntryRow("negativeWords", this.props.export.negativeWords)}
				{/* == Errands from address must include at least one of these search terms */}
				<div className="label-row">
					<div className="label-block block-rows">
						<div className="label-export-input" style={{paddingRight: '0px'}}>
							<label className="" htmlFor="basic">
								{I("Errands from address must include at least one of these search terms")}
							</label>
						</div>
						<div className="export-input with-buttons">
							<input type="text" className='text-field export-input with-buttons'
								placeholder={I("Search Terms")}
								ref={(ref) => this.positiveFromAddress = ref}
							/>
						</div>
						<div className="export-input button">
							<Button key="btn-run-export"
								color="blue"
								text={I("Add")}
								className="run-button"
								onClick={this.handleSavePsFromAddress}
							/>
						</div>
					</div>
				</div>
				{this.generateEntryRow("positiveFromAddress", this.props.export.positiveFromAddress)}
				{/* == Errands from address must not include any of these search terms */}
				<div className="label-row">
					<div className="label-block block-rows">
						<div className="label-export-input" style={{paddingRight: '0px'}}>
							<label className="" htmlFor="basic">
								{I("Errands from address must not include any of these search terms")}
							</label>
						</div>
						<div className="export-input with-buttons">
							<input type="text" className='text-field export-input with-buttons'
								placeholder={I("Search Terms")}
								ref={(ref) => this.negativeFromAddress = ref}
							/>
						</div>
						<div className="export-input button">
							<Button key="btn-run-export"
								color="blue"
								text={I("Add")}
								className="run-button"
								onClick={this.handleSaveNgFromAddress}
							/>
						</div>
					</div>
				</div>
				{this.generateEntryRow("negativeFromAddress", this.props.export.negativeFromAddress)}
			</div>
		);
	}
}

const AdvancedSearchCtnr = withAdvancedSearch(AdvancedSearchV5);

class EditExportV5 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showChannel:false,
			showArea: false,
			showDisplayKey: false,
			expNameWarning: "",
			expEmailWarning: ""
		}
	}
	componentWillMount = () => {
		if(this.props.export && this.props.export.area) {
			this.props.onFetchTags(this.props.export.area);
		}
	}
	componentDidUpdate = (prevProps, prevState) => {
		if(prevProps.export.name !== this.props.export.name) {
			if(this.props.export.name !== ""){
				this.setState({expNameWarning: ""});
			}
		}

		if(prevProps.export.emailAddress !== this.props.export.emailAddress) {
			if(this.props.export.emailAddress !== ""){
				this.setState({expEmailWarning: ""});
			}
		}
	}
	formValidation = () => {
		let r = true
		if(this.props.export.name == "") {
			this.setState({expNameWarning: INPUT_EMPTY_WARNING});
			r = false
		}
		if(this.props.export.emailExport == true &&
			this.props.export.emailAddress == "") {
			this.setState({expEmailWarning: INPUT_EMPTY_WARNING});
			r = false
		}
		return r;
	}
	getHours = () => {
		var hours = [];
		for (var i = 0; i < 24; i++) {
			var hoursObj = {};
			if(i < 10){
				hoursObj = {'Id': i,  'Name' : ('0'+i).toString() };
			}else{
				hoursObj = {'Id': i,  'Name' : i.toString() };
			}
			hours.push(hoursObj);
		}
		return hours;
	}
	getMinutes = () => {
		var minutes = [];
		for (var i = 0; i < 60; i++) {
			var minutesObj = {};
			if(i < 10){
				minutesObj = {'Id': i,  'Name' : ('0'+i).toString() };
			}else{
				minutesObj = {'Id': i,  'Name' : i.toString() };
			}
			minutes.push(minutesObj);
		}
		return minutes;
	}
	stringToArray = (items) => {
		let a = []
		if(items != "") {
			a = items.split(",").map(function(x){
				return parseInt(x, 10);
			});
		}
		return a;
	}
	renderEmailView = () => {
		if(this.props.export.emailExport == true) {
			return (
				<div>
					{/* == Email Address */}
					<TextInputRow
						id="emailAddress"
						name="emailAdress"
						className="export-input"
						label={I("Email Address")}
						placeholder={I("Email Address (required)")}
						value={this.props.export.emailAddress}
						onChange={this.handleEmailAddress}
						mandatory={true}
						warning={this.state.expEmailWarning}
						onBlur={this.handleTextInputBlur}
					/>
					{/* == Email Subject */}
					<TextInputRow
						id="emailHeader"
						name="emailHeader"
						className="export-input"
						label={I("Email Subject")}
						value={this.props.export.emailHeader}
						onChange={this.handleEmailHeader}
					/>
					{/* == Email Body == */}
					<TextInputRow
							id="emailBody"
							name="emailBody"
							className="export-input"
							label={I("Email Body")}
							value={this.props.export.emailBody}
							onChange={this.handleEmailBody}
							textArea={true}
						/>
				</div>
			);
		} else {
			return (
				<div></div>
			);
		}
	}
	renderTimeFrameType= () => {
		if(this.props.export.time == "10") {
			return (
				// == Time Frame type
				<SelectInputRow
					id="Time"
					name="Time"
					className="export-input"
					label={I("Time frame type")}
					option={TimeFrameType}
					value={this.props.export.timeFrameType}
					onSelect={this.handleTimeFrame}
				/>
			);
		}
	}
	renderTimeFrame() {
		if(this.props.export.timeFrameType == "1" && this.props.export.time == "10") {
			return (
				<div className="label-row">
					<div className="label-block">
						<label className="control-label" htmlFor="basic">
							{I('Errand interval')}
						</label>
						<div className="export-input" style={{"display": "flex"}}>
							{/* <Calender id="ExportStartDate" selectedDate={this.props.export.absoluteTimeFrom} onChange={this.handleStartDate}/> */}
							<SingleCalenderDD
								id="ExportStartDate"
								title="START"
								selected={this.props.export.absoluteTimeFrom}
								onSelect={this.handleStartDate}
								// mobile={false} to-fix
							/>
							<label className="date-dash"> - </label>
							{/* <Calender id="ExportEndDate" selectedDate={this.props.export.absoluteTimeTo} onChange={this.handleEndDate}/> */}
							<SingleCalenderDD
								id="ExportEndDate"
								title="END"
								selected={this.props.export.absoluteTimeTo}
								onSelect={this.handleEndDate}
								// mobile={false} to-fix
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				// == Errand Interval
				<SelectInputRow
					id="Interval"
					name="Interval"
					className="export-input"
					label={I("Errand Interval")}
					option={this.props.list.searchInterval}
					value={this.props.export.searchInterval}
					onSelect={this.handleRelativeInterval}
				/>
			);
		}
	}
	handleName = e => {
		this.props.onSetValue('name', e.target.value)
	}
	handleDescription = e => {
		this.props.onSetValue('description', e.target.value)
	}
	handlePrivacyCheck = (checked) => {
		this.props.onSetValue('privacyBool', checked)
		this.props.onSetValue('privacy', (checked?"Yes":"No"))
	}
	handleChannelChange = items => {
		this.props.onSetValue('channel', this.stringToArray(items))
	}
	handleToggleChannel = () => {
		this.setState({
			showChannel:!this.state.showChannel
		});
	}
	handleToggleArea = () => {
		this.setState({
			showArea: !this.state.showArea
		})
	}

	handleToggleDisplayKey = () => {
		this.setState({
			showDisplayKey: !this.state.showDisplayKey
		})
	}
	handleEmailExport = checked => {
		this.props.onSetValue('emailExport', checked)
	}
	handleEmailAddress = e => {
		this.props.onSetValue('emailAddress', e.target.value)
	}
	handleEmailHeader = e => {
		this.props.onSetValue('emailHeader', e.target.value)
	}
	handleEmailBody = e => {
		this.props.onSetValue('emailBody', e.target.value)
	}
	handleRunInterval = item => {
		this.props.onSetValue('time', parseInt(item, 10))
	}
	handleSelectHour = item => {
		this.props.onSetValue('hour', parseInt(item, 10))
	}
	handleSelectMinute = item => {
		this.props.onSetValue('minute', parseInt(item, 10))
	}
	handleTimeFrame = item => {
		this.props.onSetValue('timeFrameType', parseInt(item, 10))
	}
	handleTimeZone = item => {
		this.props.onSetValue('timeZone', parseInt(item, 10))
	}
	handleSelectArea = items => {
		let a = this.stringToArray(items)
		this.props.onSetValue('area', a)
		this.props.onFetchTags(a)
	}
	handleDisplayKey = items => {
		this.props.onSetValue('displayKey', this.stringToArray(items))
	}
	handleFileType = item => {
		this.props.onSetValue('fileType', parseInt(item, 10))
	}
	handleInclude = item => {
		this.props.onSetValue('include', (item == 1 ? true : false))
	}
	handleRelativeInterval = item => {
		this.props.onSetValue('searchInterval', parseInt(item, 10))
	}
	handleStartDate = c => {
		this.props.onSetValue('absoluteTimeFrom', c)
	}
	handleEndDate = c => {
		this.props.onSetValue('absoluteTimeTo', c)
	}
	handleCancel = () => {
		this.props.onCancel();
		this.props.onToggleExportPopUp(false);
	}
	handleGenExport = () => {
		this.props.onFetchGenExport(this.props.export.id)
	}
	handleSave = () => {
		if(this.formValidation()) {
			if(this.props.export.id > 0) {
				this.props.onSave()
			} else {
				this.props.onSetValue('activate', true)
				this.props.onCreateNew()
			}
			this.props.onToggleExportPopUp(false);
			alert(I("Success!"));
		}else {
			alert(I("One or more required field is empty. Please check and try again."));
		}
	}
	handleRunNow = () => {
		if(this.props.export.id > 0) {
			this.props.onSetValue('activate', true)
		}
		this.handleSave()
	}
	handlePreview = () => {
		this.props.onGeneratePreview()
	}
	handleCheckInput = (field, value) => {
		if(field === "name") {
			if(value.length === 0){
				this.setState({expNameWarning: INPUT_EMPTY_WARNING});
				return false;
			} else {
				this.setState({expNameWarning: ""});
				return true;
			}
		}else if (field === "emailAddress") {
			if(value.length === 0){
				this.setState({expEmailWarning: INPUT_EMPTY_WARNING});
				return false;
			} else{
				if(isValidEmail(value)){
					this.setState({expEmailWarning: ""});
					return true;
				}else{
					this.setState({expEmailWarning: MESSAGE_INVALID_EMAIL});
					return false;
				}
			}
		}
		return true;
	}
	handleTextInputBlur = (e) => {
		let field = e.target.id, value = e.target.value;
		this.handleCheckInput(field, value);
	}
	render() {
		const {
			mobile
		} = this.props;
		let downloadLink = (
			<span className="page-header-small-title" style={{float:"right"}}>
				{I("Exports")} | {I("Download")} |
				<a href="#" onClick={this.handleGenExport}>
					<i className="fas fa-download" style={{paddingLeft:"5px"}}></i>
				</a>
			</span>
		)
		let downloadButton = (
			<ButtonIcon
				className={"btn export-download float-right"}
				icon={"fas fa-download"}
				onClick={this.handleGenExport}
				title={I("Download export")}
				data-qa-id={"mobile-btn-download-export"}
				noTooltip={true}/>
		)
		const renderDownload = !mobile ? downloadLink : downloadButton;
		let emailExportHelpTxt = I("This option will result in an email being sent out to the E-mail address configured. The generated file will be included in the E-mail for downloading..");
		return (
			<div className="export-inner-wrapper">
				<div className="panel-heading">
						<span className="page-header-small-title" style={{float:"left"}}>
							{this.props.export.id > 0 ? I("Exports")+" | "+I("Edit") : I("Exports")+" | "+I("Create New")}
						</span>
						{this.props.export.id > 0 ? renderDownload : ""}
				</div>
				<div className="panel-body">
					<form>
						{/* == Export Name ==*/}
						<TextInputRow
							id="name"
							name="name"
							className="export-input"
							label={I("Export Name")}
							placeholder={I("Export Name (required)")}
							value={this.props.export.name}
							onChange={this.handleName}
							mandatory={true}
							warning={this.state.expNameWarning}
							onBlur={this.handleTextInputBlur}
							// onBlur={this.formValidation}
						/>
						{/* == Export Description ==*/}
						<TextInputRow
							id="description"
							name="description"
							className="export-input"
							label={I("Export Description")}
							placeholder={I('Export Description')}
							value={this.props.export.description}
							onChange={this.handleDescription}
							textArea={true}
						/>

						{/* ==Export viewable ..== */}
						<CheckboxInputRow
							id="privacyBool"
							data-qa-id="privacyBool"
							label={I('Export only viewable for this user')}
							onChange={this.handlePrivacyCheck}
							checked={this.props.export.privacyBool}
							className = "export-input checkbox "
							// helperTxt={"Just some helpers alright"}
						/>
						{/* == Email export when complete */}
						<CheckboxInputRow
							id="emailExport"
							data-qa-id="emailExport"
							className="export-input checkbox"
							label={I('Email export when complete')}
							onChange={this.handleEmailExport}
							checked={this.props.export.emailExport}
							helperTxt={emailExportHelpTxt}
						/>
						{this.renderEmailView()}
						{/*== Time Zone ==*/}
						<SelectInputRow
							id="TimeZone"
							name="Time Zonne"
							className="export-input"
							idFields={{id: "Id", name: "Name"}}
							label={I('Time Zone')}
							option={this.props.list.timeZone}
							value={this.props.export.timeZone}
							onSelect={this.handleTimeZone}
						/>
						{/* ==Scheduled Time To Run == */}
						<div className="label-row">
							<div className="label-block">
								<div className="label-runInterval">
									<label htmlFor="RunInterval">{I('Schedule time to run export')}</label>
								</div>
								<div className="export-input select-row">
									<div className="inline-selection-row">
										<SelectionBox
											id="RunInterval"
											name="Run Interval"
											className="col-schedule-interval"
											options={this.props.list.runInterval}
											selected={this.props.export.time}
											onSelect={this.handleRunInterval}
										/>
										<SelectionBox
											id="ReportHours"
											name={I("Hours")}
											className="col-schedule-time"
											options={this.getHours()}
											selected={this.props.export.hour}
											onSelect={this.handleSelectHour}
										/>
										<SelectionBox
											id="ReportMinutes"
											name={I("Minutes")}
											className="col-schedule-time"
											options={this.getMinutes()}
											selected={this.props.export.minute}
											onSelect={this.handleSelectMinute}
										/>
									</div>
								</div>
							</div>
						</div>
						{this.renderTimeFrameType()}
						{this.renderTimeFrame()}
						{/* == Channels */}
						<FormInputWithLabelRow label={I("Channels")} className={"export-multiselect-dd"}>
							<SerialMultiSelect
								id={"Channels"}
								title={I("Channels")}
								className={"popup-multi-select"}
								overrideTitle={true}
								groupSelect={true}
								// nested={{key: 'Areas'}}
								data={this.props.list.channel}
								idFields={{id: "Id", name: "Name"}}
								selected={this.props.export.channel}
								onSelect={this.handleChannelChange}
								onToggle={this.handleToggleChannel}
								show={this.state.showChannel}
								selectAll={true}
								selectNone={true}
								// showChevron={true}
							/>
						</FormInputWithLabelRow>
						{/* == Source for errands */}
						<FormInputWithLabelRow label={I("Source for Errands")} className={"export-multiselect-dd"}>
							<SerialMultiSelect
								id={"SourceArea"}
								title={I("Source for Errands")}
								className={"popup-multi-select"}
								overrideTitle={true}
								groupSelect={true}
								// nested={{key: 'Areas'}}
								data={this.props.areas}
								idFields={{id: "Id", name: "Name"}}
								selected={this.props.export.area}
								onSelect={this.handleSelectArea}
								onToggle={this.handleToggleArea}
								show={this.state.showArea}
								selectAll={true}
								selectNone={true}
								// showChevron={true}
							/>
							{/* <small className="report-field-tips">
								{I("Selected Areas")}
							</small> */}
							<div style={{width: "100%", display: "inline-block"}}>
								<SelectedReadOnly title={I("Selected Areas")} selected={this.props.export.area} data={this.props.areas} className="export-selected-options" />
							</div>
						</FormInputWithLabelRow>
						{/* == Columns to display in export */}
						<FormInputWithLabelRow label={I("Columns to display in export")} className={"export-multiselect-dd"}>
							<SerialMultiSelect
								id={"displayKeys"}
								title={I("Please select a key")}
								className={"popup-multi-select"}
								overrideTitle={true}
								groupSelect={true}
								// nested={{key: 'Areas'}}
								data={this.props.export.allDisplayKey}
								idFields={{id: "Id", name: "Name"}}
								selected={this.props.export.displayKey}
								onSelect={this.handleDisplayKey}
								onToggle={this.handleToggleDisplayKey}
								show={this.state.showDisplayKey}
								selectAll={true}
								selectNone={true}
								// showChevron={true}
							/>
							<div style={{width: "100%", display: "inline-block"}}>
								<SelectedReadOnly title={I("Selected Display Keys")} selected={this.props.export.displayKey} data={this.props.export.allDisplayKey} className="export-selected-options" />
							</div>
						</FormInputWithLabelRow>
						{/* == Export File Type */}
						<SelectInputRow
							id="fileType"
							name="File Type"
							className="export-input"
							label= "Export File Type"
							option={this.props.list.fileType}
							value={this.props.export.fileType}
							onSelect={this.handleFileType}
						/>
						{/* == Advanced Export */}
						<SelectInputRow
							id="SearchType"
							name="Search Type"
							className="export-input"
							label= "Advanced Export"
							option={Include}
							value={this.props.export.include === true ? 1 : 2}
							onSelect={this.handleInclude}
						/>
						{this.props.export.include == true ? "" : <AdvancedSearchCtnr export={this.props.export} />}
						<div className="label-row export-buttons-wrapper" style={{display: "inline-flex", float: "right"}}>
							<Button key="btn-cancel-report"
									color="red"
									text={I("Cancel")}
									className="cancel-button"
									onClick={this.handleCancel}
							/>
							<Button key="btn-preview-export"
									color="yellow"
									text={I("Preview")}
									className="preview-button"
									onClick={this.handlePreview}
							/>
							<Button key="btn-save-export"
									color="blue"
									text={I("Save")}
									className="save-button"
									onClick={this.handleSave}
							/>
							<Button key="btn-run-export"
									color="blue"
									text={I("Run now")}
									className="run-button"
									onClick={this.handleRunNow}
							/>
						</div>
					</form>
				</div>
			</div>
		);
	}
}
export default EditExportV5;
