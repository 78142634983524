import { connect } from 'react-redux';
import Organisations from './list';
import {
	skillProficiencySelector,
	skillsAdminSelector,
	skillAdminAreaListSelector
} from '../../../redux/selectors/admin';
import {
	fetchSkillProficiency ,
	fetchSkills,
	saveAreaSkill,
	fetchSkillAreas,
	removeSkillArea
} from '../../../redux/actions/async/admin';
import { adminActionAlert } from '../../../redux/actions/admin';
import {
	fetchOrganisationList,
	getOrganisationById,
	SaveExternalSetting
} from '../../../redux/actions/async/organisation';

const mapOrganisationStateToProp = state => {
	const org = state.app.organisations
	, admin = state.app.admin
	, adminSt = admin.admin;
	return {
		orgList: org.orgList.list,
		org: org.org.data,
		proficiencyList: skillProficiencySelector(state),
		skillList: skillsAdminSelector(state),
		areaSkills: skillAdminAreaListSelector(state),
		adminActionAlert: adminSt.adminAlert
	};
};

const mapOrganisationDispatchToProp = (dispatch) => {
	return {
		onLoadTableList: (p) => {
			dispatch(fetchOrganisationList(p));
		},
		getOrganisation: (p) => {
			dispatch(getOrganisationById(p));
		},
		saveExternal: (p) => {
			dispatch(SaveExternalSetting(p));
		},
		onLoadAreaSkillsTab: (areaId) => {
			dispatch(fetchSkillProficiency());
			dispatch(fetchSkills());
			dispatch(fetchSkillAreas(areaId));
		},
		onSaveAreaSkill: (p) => {
			dispatch(saveAreaSkill(p));
		},
		onDeleteAreaSkill: (p) => {
			dispatch(removeSkillArea(p));
		},
		onDismissAlert: () => {
			const alertMsg = {
				show: false,
				msg: "",
				type: ""
			}
			dispatch(adminActionAlert(alertMsg));
		},
	}
};

const OrganisationCtnr = connect(mapOrganisationStateToProp, mapOrganisationDispatchToProp)(Organisations);

export default OrganisationCtnr;
