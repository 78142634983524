import React from 'react';
//dev component
import TableComponent from '../../components/TableComponent';

let removeButton = {
	action: "{id}",
	name: I("Remove"),
	className: "fa fa-remove"
}
let downloadButton = {
	action: "{id}",
	name: I("Download"),
	className: "fa fa-folder"
}
let column = [
	{ header:I("Name"), key:"exportName" },
	{ header:I("Started"), key:"startTime" },
	{ header:I("Finished"), key:"finishTime" },
	{ header:I("Size"), key:"size" },
	{ header:"", type:"actionLink", data: downloadButton },
	{ header:"", type:"button", data:removeButton }
]
class GeneratedExport extends React.Component {
	constructor(props) {
		super(props);
	}
	componentWillMount = () => {}
	handleRemove = id => {
		this.props.onRemove(id)
	}
	handleDownload = id => {
		this.props.onDownload(id)
	}
	render() {
		return(
			<div>
				<div>
					<h4 className="page-header">
						{I('Generated Exports')} | <span className="page-header-small-title">{I('List')}</span>
					</h4>
				</div>
				<br />
				<TableComponent
					dataSource={this.props.list}
					columns={column}
					id={"GeneratedExportTable"}
					itemDeleteAction={this.handleRemove}
					onActionRow={this.handleDownload}
					paginate={true}
					pageSize={defaultPaginationSize}
					enablePageSizeChange={false}
				/>
			</div>
		);
	}
}
export default GeneratedExport
