import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
//dev component
import { RightEndHelper as Helper } from '../../../reactcomponents/Admin';
import WorkingHours from '../../../components/v5/WorkingHours';
import OpenExternalV5 from '../../../components/v5/ExternalSystem';
import BrightSettingV5 from '../../../components/v5/BrightSetting';
import ArtificialSettingV5 from '../../../components/v5/ArtificialSol';
//FLUX actions and store
import AdminAction from '../../../flux/actions/adminActions';
//feature
import Feature from '../../../components/v5/Feature';
import { FooterButton } from '../../../reactcomponents/Admin';
import { SelectInputRow } from '../../../reactcomponents/Form';
import { formatValueToNameObj } from '../../../common/v5/helpers';

global.ORG = 1, global.WORKINGHOUR = 2, global.CLOSERECEIPT = 3, global.OPENEXTERNAL = 4, global.FEEDBACK = 5, global.BRIGHT = 6, global.ARTIFICIALSOL = 7;
global.CONVERSATIONBOTWORKINGHOUR = 8;

export default class OrganisationEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: I("Add new organization"),
			activeTab: ORG,
			features: {},
			org: {
				active: false,
				anonymizeContact: false,
				anonymizeDay: 0,
				areaFileArchive: [],
				areaList: [],
				artificialSettings: {},
				brightSettings: {},
				closeReceiptRules: {},
				conversationBotWorkingTimeExceptions: [],
				conversationBotWorkingTimeRules: {},
				externalId: "",
				externalSystemRules: [],
				feedbackSettings: {},
				id: 0,
				languages: [],
				name: "",
				openExternalSystemRulesId: 0,
				proActiveChat: "",
				systemgroupId: 0,
				timeZoneId: 0,
				timezoneList: [],
				workingTimeExceptions: [],
				workingTimeRules: {},
			},
			user: {},
			saving: false
		}
		this.isEmpty = this.isEmpty.bind(this);
	}
	toggle = (tab) => {
		if (this.state.activeTab === ORG && this.props.id <= 0) {
			return;
		}
		if (this.state.activeTab !== tab) {
			this.setState({ activeTab: tab });
		}
	}
	componentWillMount = () => {
		this.props.renderOrg(this.props.id);
	}
	componentDidMount = () => {
		if (this.props.id >= 1) {
			this.setState({ title: I("Edit organization") });
		}
	}
	handleFileUpload = (attachment) => {
		console.info(attachment);
	}
	handleGetOrg = () => {
		this.props.renderOrg(this.props.id);
	}
	hanleSaveOrg = (ActionId, values) => {
		this.setState({ saving: true });
		AdminAction.saveAdminOrganisation(this.props.id, values)
			.then(function (data) {
				this.setState({ saving: false });
				if (this.props.id !== data.orgId) {
					this.props.onCloseEdit();
				}
			}.bind(this));
	}
	handleSaveExternal = (id, url) => {
		this.props.saveExternal({ id: id, url: url });
	}
	handleCancel = () => {
		window.location.hash = "#organisations";
	}
	handleWorkingException = (values) => {
		AdminAction.saveOrganisationException(this.props.id, values)
			.then(() => {
				console.log("Done");
			});
	}
	isEmpty = (obj) => {
		return Object.keys(obj).length === 0;
	}
	render = () => {
		var data = this.state.org;
		var element = null;
		if (this.props.data) {
			if (!this.isEmpty(this.props.data)) {
				data = this.props.data.org;
				if (this.props.id > 0) {
					element = <React.Fragment>
						<TabPane tabId={WORKINGHOUR}>
							<WorkingHours
								workingTimeRules={data.workingTimeRules}
								workingTimeExceptions={data.workingTimeExceptions}
								usedFor="org"
								saving={this.state.saving}
								saveOrganization={this.hanleSaveOrg}
								cancelToOrg={this.props.onCloseEdit}
								saveWorkingHourExcp={this.handleWorkingException}
								updateRulesField={"workingTimeRules"}
								updateExceptionsField={"workingTimeExceptions"}
							/>
						</TabPane>
						<TabPane tabId={OPENEXTERNAL}>
						{data.externalSystemRules && <OpenExternalV5
								id={data.id}
								externalSystemRules={data.externalSystemRules}
								openExternalSystemRulesId={data.openExternalSystemRulesId}
								saveOrganization={this.hanleSaveOrg}
								saveOrgExtSysRules={this.handleSaveExternal}
								getOrg={this.handleGetOrg}
								isArea={false}
								cancelToOrg={this.props.onCloseEdit}
								usedFor="org"
								saving={this.state.saving}
							/>}
						</TabPane>
						<TabPane tabId={BRIGHT}>
							{data.brightSettings &&<BrightSettingV5
								id={data.id}
								brightSettings={data.brightSettings}
								saveOrganization={this.hanleSaveOrg}
								cancelToOrg={this.handleCancel}
								usedFor="org"
								saving={this.state.saving}
							/>}
						</TabPane>
						<TabPane tabId={ARTIFICIALSOL}>
							{data.artificialSettings &&<ArtificialSettingV5
								id={data.id}
								artificialSettings={data.artificialSettings}
								saveOrganization={this.hanleSaveOrg}
								cancelToOrg={this.handleCancel}
								usedFor="org"
								saving={this.state.saving}
							/>}
						</TabPane>
						<TabPane tabId={CONVERSATIONBOTWORKINGHOUR}>
							<WorkingHours
								workingTimeRules={data.conversationBotWorkingTimeRules}
								workingTimeExceptions={data.conversationBotWorkingTimeExceptions}
								usedFor="org"
								saving={this.state.saving}
								saveOrganization={this.hanleSaveOrg}
								cancelToOrg={this.props.onCloseEdit}
								saveWorkingHourExcp={this.handleWorkingException}
								updateRulesField={"conversationBotWorkingTimeRules"}
								updateExceptionsField={"conversationBotWorkingTimeExceptions"}
							/>
						</TabPane>
					</React.Fragment>
				} else {
					element = null;
				}
			}
		}
		var iconTimeStyle = {
			cursor: 'pointer',
			verticalAlign: 'sub'
		};
		var conversationBotTab =
			<li role="presentation" id="orgTab_5" className="nav-item" data-qa-id="QA_tab_working_bot">
				<a className={this.state.activeTab === CONVERSATIONBOTWORKINGHOUR ? "active" : ""} href="#library"
					onClick={() => this.toggle(CONVERSATIONBOTWORKINGHOUR)}>{I("Working hours for Conversation bot")}
				</a>
			</li>
		return (
			<div className="organization-edit">
				<div className="panel panel-default">
					<div className="row">
						<div className="col-lg-8 panel-heading">
							<h3>
								<span className='edit-header-icon'>
									<i
										className='icon-edit'
									/>
								</span>
								{this.state.title}
							</h3>
						</div>
						<div className='col-lg-4' style={{ textAlign: 'right' }}>
							<span style={iconTimeStyle} onClick={this.props.onCloseEdit}>
								<i className='icon-times'></i>
							</span>
						</div>
					</div>
					<div className="panel-body">
						<ul className="nav org-nav-tabs col-md-12">
							<li role="presentation" id="orgTab_0" className="nav-item" data-qa-id="QA_org">
								<a className={this.state.activeTab === ORG ? "active" : ""} href="#library"
									onClick={() => this.toggle(ORG)}>{I('Organization')}
								</a>
							</li>
							<li role="presentation" id="orgTab_1" className="nav-item" data-qa-id="QA_org_working">
								<a className={this.state.activeTab === WORKINGHOUR ? "active" : ""} href="#library"
									onClick={() => this.toggle(WORKINGHOUR)}>{I('Working hours')}
								</a>
							</li>
							<Feature tag="open-external-system">
								<li role="presentation" id="orgTab_2" className="nav-item" data-qa-id="QA_org_external">
									<a className={this.state.activeTab === OPENEXTERNAL ? "active" : ""} href="#library"
										onClick={() => this.toggle(OPENEXTERNAL)}>{I('Open External System settings')}
									</a>
								</li>
							</Feature>
							<Feature tag="bright-integration">
								<li role="presentation" id="orgTab_3" className="nav-item" data-qa-id="QA_org_bright">
									<a className={this.state.activeTab === BRIGHT ? "active" : ""} href="#library"
										onClick={() => this.toggle(BRIGHT)}>{I('Bright Settings')}
									</a>
								</li>
							</Feature>
							<Feature tag="artificial-solutions">
								<li role="presentation" id="orgTab_4" className="nav-item" data-qa-id="QA_org_artificial">
									<a className={this.state.activeTab === ARTIFICIALSOL ? "active" : ""} href="#library"
										onClick={() => this.toggle(ARTIFICIALSOL)}>{I('Artificial Solutions Settings')}
									</a>
								</li>
							</Feature>
							{conversationBotTab}
						</ul>
						<TabContent activeTab={this.state.activeTab}>
							<TabPane tabId={ORG}>
								<OrganizationForm
									org={data}
									saveOrganization={this.hanleSaveOrg}
									cancel={this.props.onCloseEdit}
									saving={this.state.saving}
								/>
							</TabPane>
							{element}
						</TabContent>
					</div>
				</div>
			</div>
		);
	}
}

class OrganizationForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			externalId: "",
			proActiveChat: "",
			timeZoneId: 0,
			anonymizeDay: 0,
			anonymizeContact: false
		}
	}
	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.setState({
			[name]: value
		});
	}
	static getDerivedStateFromProps =(props, state)=>{
		if(JSON.stringify(props.org) !== JSON.stringify(state.org)) {
			let org = props.org;
			return {
				name: org.name,
				externalId: org.externalId,
				proActiveChat: org.proActiveChat,
				timeZoneId: org.timeZoneId,
				anonymizeDay: org.anonymizeDay,
				anonymizeContact: org.anonymizeContact,
				org: props.org
			};
		}
		return null;
	}
	handleChangeData = (a, b) => {
		const name = b;
		this.setState({
			[name]: a
		});
	}
	saveOrg = () => {
		let s = this.state;
		s.settingsPresent = true;
		this.props.saveOrganization(ORG, this.state);
	}
	render = () => {
		let p = this.props.org;
		let s = this.state;
		if (p === 'undefined')
			return null;
		const isEnabled = this.state.name !== "";
		return (
			<div className="row">
				<div className="col-lg-12">
					<div className="form-group-org">
						<div className="row">
							<label className="col-lg-10 col-sm-1">{I("Name")}:<span className="mandatory">* </span></label>
						</div>
						<div className="row">
							<div className="col-lg-12 col-sm-4">
								<input name="name" className="form-control input-sm" type="text" value={s.name} onChange={this.handleInputChange} />
							</div>
						</div>

					</div>
					<div className="form-group-org">
						<div className="row">
							<label className="col-lg-10 col-sm-1">{I("External ID")}:</label>
						</div>
						<div className="row">
							<div className="col-lg-12 col-sm-4">
								<input name="externalId" className="form-control input-sm" type="text" value={s.externalId} onChange={this.handleInputChange} />
							</div>
						</div>
					</div>
					<div className="row">
						<Feature tag="chat">
							<div className="col-lg-6">
								<div className="form-group-org">
									<div className="row">
										<label className="col-lg-12">{I("Available Chat Slot")}:</label>
									</div>
									<div className="row">
										<div className="col-lg-12">
											<input name="proActiveChat" className="form-control input-sm" type="text" value={s.proActiveChat} onChange={this.handleInputChange} />
										</div>
									</div>
								</div>
							</div>
						</Feature>
						<Feature tag="show-time-zone">
							<div className="col-lg-6">
								<div className="form-group-org">
									<div className="row">
										<div className="col-lg-12">
											<SelectInputRow
												id="timeZoneId"
												name="timeZoneId"
												className="timeZoneId"
												label={I('Time Zone')}
												option={formatValueToNameObj(p.timezoneList, "id", "label")}
												value={s.timeZoneId}
												onSelect={this.handleChangeData}
											/>
										</div>
									</div>
								</div>
							</div>
						</Feature>
					</div>
					<Feature tag="data-protection.anonymize">
						<div>
							<div className="form-group-org">
								<div className="row">
									<label className="col-lg-10 col-sm-1">{I("Days until anonymize")}:</label>
								</div>
								<div className="row">
									<div className="col-lg-11 col-sm-4">
										<input name="anonymizeDay" className="form-control input-sm" type="text" value={s.anonymizeDay} onChange={this.handleInputChange} />
									</div>
									<div className="col-lg-1 col-sm-4">
										<Helper helper={I("Number of days before errands are anonymized. Put 0 for no anonymize")} />
									</div>
								</div>
							</div>
							{s.anonymizeDay != 0 &&
								<div className="form-group-org">
									<div className="row">
										<label className="col-lg-10 col-sm-1">{I("Anonymize contact details")}:</label>
									</div>
									<div className="row">
										<div className="col-lg-11 col-sm-4">
											<input type="checkbox" name="anonymizeContact" onChange={this.handleInputChange} checked={s.anonymizeContact} />
										</div>
										<div className="col-lg-1 col-sm-4">
											<Helper helper={I("Enable to anonymize contact details during schedule anonymize process")} />
										</div>
									</div>
								</div>
							}
						</div>
					</Feature>
					<FooterButton cancel={this.props.cancel}
						save={this.saveOrg}
						saveTxt={this.props.saving ? I("Saving...") : I("Save")}
						disableSave={this.props.saving}
					/>
				</div>
			</div>);
	}
}
