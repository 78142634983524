import React from 'react';
import { connect } from 'react-redux';
import { push } from '../../common/v5/utils';
import { V5 } from '../../common/path';
import {
	onceAreas
	, decryptAndLoadErrand
} from '../../redux/actions/async/workflow';
import {
	exportList,
	fetchTagList,
	saveExport,
	createExport,
	removeExport,
	activateExport,
	generatePreview,
	fetchGeneratedList,
	removeGeneratedExport,
	fileDownload,
	fetchGeneratedCdrList,
	removeGeneratedCdrExport,
	exportCallList,
	exportCallCreate,
	exportCallRemove,
	exportCallActivate
} from '../../redux/actions/async/export';
import {
	setSelectedExport,
	setExportValue,
	setErrandCategory,
	removeErrandCategory,
	removeSearchTerm,
	cancelCurrentExport,
	previewClose,
	closeGeneratedCdr,
	toggleExportPopUp
} from '../../redux/actions/export';
import {
	adminActionStatus,
	changeAdminView,
} from '../../redux/actions/admin';
import {
	selectToggleSideBar,
	selectCollapseSideBar,
	setMobileView,
	resetWorkflowView
} from '../../redux/actions/workflow';
import {
	activeAreaListSelector,
} from '../../redux/selectors/workflow';
import ExportList from './list'; //for old Export
import EditExport, { AdvancedSearch } from './edit'; //for old Export
// import ExportV5, { GeneratedPreviewTable, ExportPreviewTable } from '../../views/v5/exportV5'; //for new Export
// import EditExportV5, { AdvancedSearchV5 } from './exportEditV5'; //for new Export
import GeneratedExport from './download';
import ExportPreview from './preview';
import ExportCalls from '../../components/v5/ExportCalls';
import {
	exportCallListSelector,
	exportTimezoneSelector,
	exportRunIntervalSelector,
	exportFileTypeSelector,
	exportSearchIntervalSelector,
	exportCallDisplayColumnsSelector,
	exportCallAdvanceFilterSelector
}
from '../../redux/selectors/export';
import { MM_EXPORT, MM_EXPORT_CALL } from '../../common/v5/constants';
import { orgCompleteListsAreaMemo } from '../../redux/selectors/server';

const mapExportPreview = (st) => {
	const ex = st.app.export
	const data = ex.generatedPreview.data
	const s = {
		list: data.list,
		column: data.column
	}
	return s;
}

let ExportPreviewPlaceholder = ExportPreview;

export const withMapExportPreview = connect(mapExportPreview)
export const ExportPreviewCtnr = withMapExportPreview(ExportPreviewPlaceholder)

const mapGeneratedExport = (st) => {
	const ex = st.app.export
	const data = ex.generatedList.data
	let tl = []
	if (data != null) {
		data.list.forEach(function(v, i) {
			tl.push(data.normalizeList[v])
		})
	}
	const s = {
		list: tl,
	}
	return s;
}
const mapGeneratedExportCallbacks = dispatch => {
	return {
		onDownload: id => {
			dispatch(fileDownload(id))
		},
		onRemove: id => {
			return dispatch(removeGeneratedExport(id))
		}
	}
}

let GeneratedExportPlaceholder = GeneratedExport;

export const withGeneratedExport = connect(mapGeneratedExport, mapGeneratedExportCallbacks);
export const GeneratedExportCtnr = withGeneratedExport(GeneratedExportPlaceholder)

const mapAdvancedInfo = (st) => {
	const ex = st.app.export
	const s = {
		tags: (ex.tagList.data != null ? ex.tagList.data.tags : []),
	};
	return s
}
const mapAdvancedInfoCallbacks = dispatch => {
	return {
		onSetValue: (field, value) => {
			dispatch(setExportValue(field, value))
		},
		onSetErrandCategory: (field, value) => {
			dispatch(setErrandCategory(field, value))
		},
		onRemoveErrandCategory: (field, value) => {
			dispatch(removeErrandCategory(field, value))
		},
		onRemoveSearchTerm: (flag, item) => {
			dispatch(removeSearchTerm(flag, item))
		}
	}
}

let AdvancedSearchPlaceholder = AdvancedSearch;


export const withAdvancedSearch = connect(mapAdvancedInfo, mapAdvancedInfoCallbacks)
export const AdvancedSearchCtnr = withAdvancedSearch(AdvancedSearchPlaceholder)

const mapExportInfo = (state, props) => {
	const ex = state.app.export;
	const s = {
		export: ex.ui.currentExport,
		list: ex.exportList.data ? ex.exportList.data.dataSourceList : [],
		areas: activeAreaListSelector(state, props),
		editUi: ex.editUi
	}
	return s;
}

const mapExportInfoCallbacks = dispatch => {
	return {
		onSetValue: (field, value) => {
			dispatch(setExportValue(field, value))
		},
		onCancel: () => {
			dispatch(cancelCurrentExport())
		},
		onSave: params => {
			dispatch(saveExport())
		},
		onCreateNew: params => {
			dispatch(createExport())
		},
		onFetchGenExport: id => {
			dispatch(fetchGeneratedList(id))
		},
		onFetchTags: items => {
			dispatch(fetchTagList(items))
		},
		onGeneratePreview: () => {
			dispatch(generatePreview())
		},
		onToggleExportPopUp: (show) => {
			dispatch(toggleExportPopUp(show));
		},
		changePage: (url, label, id, menuId) => {
			dispatch(push(url));
		}
	}
}

let ExportEditPlaceholder = EditExport;

export const withExportInfo = connect(mapExportInfo, mapExportInfoCallbacks)
export const ExportInfoCtnr = withExportInfo(ExportEditPlaceholder)

const mapExports = st => {
	const ex = st.app.export;
	const data = ex.exportList.data;
	const wf = st.app.workflow;
	let tl = [];
	if (data != null) {
		data.tableList.forEach(function(v, i) {
			tl.push(data.normalizeList[v])
		})
	}
	const s = {
		tableList: tl,
		ui: ex.ui,
		baseUi: wf.ui

	}
	return s
}

const mapExportCallbacks = dispatch => {
	return {
		onLoad: () => {
			dispatch(cancelCurrentExport());
		},
		onSetSelectedExport: id => {
			dispatch(setSelectedExport(id, MM_EXPORT))
		},
		onPreviewClose: () => {
			dispatch(previewClose())
		},
		onActivate: (id, value) => {
			dispatch(activateExport(id, value))
		},
		onRemove: id => {
			dispatch(removeExport(id))
		},
		fetchExportList: () => {
			dispatch(exportList())
		},
		fetchAreas: () => {
			dispatch(onceAreas())
		},
		onToggleSideBar: (toggle) => {
			dispatch(selectToggleSideBar(toggle));
		},
		onCollapseSideBar: (toggle) => {
			dispatch(selectCollapseSideBar(toggle));
		},
		onToggleExportPopUp: (show) => {
			dispatch(toggleExportPopUp(show));
		},
		onCancel: () => {
			dispatch(cancelCurrentExport())
		},
		onChangeView: (view, url) => {
			dispatch(push(url)).then(() => dispatch(changeAdminView(view)));
		},
		onSetMobileView: (toggle) => {
			dispatch(setMobileView(toggle));
		},
		onMainView: () => {
			dispatch(resetWorkflowView());
		},
		simpleLoadAndOpen: (encoded, eid) => {
			dispatch(push(V5))
			.then(() => {
				dispatch(decryptAndLoadErrand(encoded, eid));
			});
		},
		onChangePage: (url) => {
			dispatch(push(url));
		}
	}
}

let ExportPlaceholder = ExportList;

export const withExport = connect(mapExports, mapExportCallbacks)
const ExportCtnr = withExport(ExportPlaceholder)

const mapGeneratedCdrExport = (st) => {
	const ex = st.app.export
		console.info("ex", ex);
	const data = ex.generatedCdrList.data
	let tl = []
	if (data != null) {
		data.list.forEach(function(v, i) {
			tl.push(data.normalizeList[v])
		})
	}
	const s = {
		list: tl,
	}
	return s;
}
const mapGeneratedCdrExportCallbacks = dispatch => {
	return {
		onDownload: id => {
			dispatch(fileDownload(id))
		},
		onRemove: id => {
			return dispatch(removeGeneratedCdrExport(id))
		}
	}
}

let GeneratedCdrExportPlaceholder = GeneratedExport;

export const withGeneratedCdrExport = connect(mapGeneratedCdrExport, mapGeneratedCdrExportCallbacks);
export const GeneratedCdrExportCtnr = withGeneratedCdrExport(GeneratedCdrExportPlaceholder)

const mapCallExports = st => {
	const exc = st.app.export;
	const wf = st.app.workflow;
	const admin = st.app.admin;
	const orgsWithAreas = wf.areas ? wf.areas.data : [];
	const s = {
		input: exc.ui.currentExport,
		activeId: exc.ui.currentExport ? exc.ui.currentExport.id : 0,
		ui: exc.ui,
		baseUi: wf.ui,
		list: exportCallListSelector(st),
		view: "",
		allAreas: activeAreaListSelector(st),
		areas: orgsWithAreas ? orgsWithAreas.areas : [],
		timezone: exportTimezoneSelector(st),
		fileType: exportFileTypeSelector(st),
		runInterval: exportRunIntervalSelector(st),
		searchInterval: exportSearchIntervalSelector(st),
		columnsToDisplay: exportCallDisplayColumnsSelector(st),
		advanceCallFilters: exportCallAdvanceFilterSelector(st),
		saveStatus: admin.admin.adminStatus,
	}
	return s
}

const mapCallExportCallbacks = dispatch => {
	return {
		onLoad: () => {
			dispatch(exportCallList());
			dispatch(onceAreas());
		},
		onLoadToEdit: (id) => {
			dispatch(setSelectedExport(id, MM_EXPORT_CALL));
		},
		onSetValue: (field, value, isRemove) => {
			dispatch(setExportValue(field, value, isRemove));
		},
		onSave: () => {
			dispatch(exportCallCreate());
			dispatch(adminActionStatus({ status: 1, msg: I("Pending") }));
		},
		onRemove: id => {
			dispatch(exportCallRemove(id));
		},
		onToggleSideBar: (toggle) => {
			dispatch(selectToggleSideBar(toggle));
		},
		onCollapseSideBar: (toggle) => {
			dispatch(selectCollapseSideBar(toggle));
		},
		onCancel: () => {
			dispatch(cancelCurrentExport())
		},
		onChangeView: (view, url) => {
			dispatch(push(url)).then(() => dispatch(changeAdminView(view)));
		},
		onSetMobileView: (toggle) => {
			dispatch(setMobileView(toggle));
		},
		onMainView: () => {
			dispatch(resetWorkflowView());
		},
		onChangePage: (url) => {
			dispatch(push(url));
		},
		onDeleteFromList: (id) => {
			dispatch(exportCallRemove(id));
		},
		onRunCallExport: (id) => {
			dispatch(setExportValue('activate', true))
			.then(() => {
				dispatch(exportCallCreate(true));
			});
		},
		onPreviewCallExport: (id) => {
			dispatch(generatePreview());
		},
		onClosePreviewCallExport: () => {
			dispatch(previewClose())
		},
		onCloseExportPreview: () => {
			dispatch(closeGeneratedCdr());
		},
		onCloseForm: () => {
			dispatch(cancelCurrentExport());
		},
		onCdrExportDownload: (id) => {
			dispatch(fetchGeneratedCdrList(id))
		},
		simpleLoadAndOpen: (encoded, eid) => {
			dispatch(push(V5))
			.then(() => {
				dispatch(decryptAndLoadErrand(encoded, eid));
			});
		},
		onClickToggleEnable: (id, toggle) => {
			dispatch(exportCallActivate(id, toggle));
		}
	}
}

export const ExportCallCtnr = connect(mapCallExports, mapCallExportCallbacks)(ExportCalls);

export default ExportCtnr
