import React from 'react';
//dev component
import TableComponent from '../../components/TableComponent';
import BootstrapModal from '../../components/BootstrapModal';
//views
import { ExportInfoCtnr, GeneratedExportCtnr, ExportPreviewCtnr } from './exportCtnr';
let removeButton = {
	action: "{id}",
	name: I("Remove"),
	className: "fa fa-remove"
}
let column = [
	{ header: I("Name"), key:"name" },
	{ header: I("Created by"), key:"createdBy" },
	{ header: I("Personal"), key:"privacy" },
	{ header: I("Run"), key:"action" },
	{ header:"", type:"button", data:removeButton },
	{ header: I("Active"), key:"activate", type:"controlled-checkbox"},
];
class ExportList extends React.Component {
	constructor(props) {
		super(props);
	}
	componentWillMount = () => {
		this.props.fetchExportList();
		this.props.fetchAreas();
	}
	createNew = () => {
		this.props.onSetSelectedExport(0)
	}
	rowHighlightHandle = id => {
		this.props.onSetSelectedExport(id)
	}
	handleActivate = (id, md, checked) => {
		this.props.onActivate(id, checked)
	}
	handleRemove = id => {
		this.props.onRemove(id)
	}
	handlePreviewClose = (e) => {
		this.props.onPreviewClose()
	}
	render() {
		let settings = "";
		let preview = "";
		if(this.props.ui.editSettings == true) {
			settings = (
				<ExportInfoCtnr />
			);
		}
		if(this.props.ui.showGeneratedExport == true) {
			preview = (
				<BootstrapModal
					id="generated_export_modal"
					width="800px"
					height="auto"
					centerDialog={true}
					isDraggable={true}
					bodyHeight="500px"
					backgroundScroll={true}
					onClose={this.handlePreviewClose}
					title={I("Preview")}>
						<div id="content-to-preview">
							<GeneratedExportCtnr />
						</div>
				</BootstrapModal>
			);
		}
		if(this.props.ui.showExportPreview == true) {
			preview = (
				<BootstrapModal
					id="generated_export_preview_modal"
					width="800px"
					height="auto"
					centerDialog={true}
					isDraggable={true}
					bodyHeight="500px"
					backgroundScroll={true}
					onClose={this.handlePreviewClose}
					title={I("Preview")}>
						<div id="content-to-preview">
							<ExportPreviewCtnr />
						</div>
				</BootstrapModal>
			);
		}
		return(
			<div id="wrapper" className="admin-profile">
				<div id="page-wrapper">
					<div className="row">
						<div className="export-preview">{preview}</div>
					</div>
					<div className="row">
						<div className="col-lg-6">
							<h4 className="page-header">
								{I("Exports")} | <span className="page-header-small-title">{I("List")}</span>
								<button className="createButton btn btn-primary btn-sm"
									style={{'float':'right'}} type="button"
									onClick={this.createNew}>{I("Create New")}
								</button>
								<div className="clear"></div>
							</h4>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-6 col-lg-push-6">
							<div className="col-lg-12" id="edit-export-panel">{settings}</div>
						</div>
						<div className="col-lg-6 col-lg-pull-6 export-table-list">
							<TableComponent
								dataSource={this.props.tableList}
								columns={column}
								highlightRow={this.rowHighlightHandle}
								id={"ExportTable"}
								itemDeleteAction={this.handleRemove}
								onCtrlCheckboxChange={this.handleActivate}
								paginate={true}
								pageSize={defaultPaginationSize}
								enablePageSizeChange={false}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default ExportList
