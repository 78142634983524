import React from 'react';
//dev component
import TableComponent from '../../components/TableComponent';

class ExportPreview extends React.Component {
	constructor(props) {
		super(props);
	}
	componentWillMount = () => {}
	generateColumn = () => {
		let column = []
		for(let i = 0; i < this.props.column.length; i++) {
			column.push({
				'header': I("{ITEM}").replace("{ITEM}", this.props.column[i]),
				'key': this.props.column[i]
			})
		}
		return column
	}
	render() {
		return(
			<div>
				<div>
					<h4 className="page-header">
						{I('Export Preview')} | <span className="page-header-small-title">{I('List')}</span>
					</h4>
					<br />
					<p>{I("The above preview is generated without any advanced errand filtering and my contain duplicates that will not be in the final export. This is to ensure that previews are generated quickly.")}
					</p>
				</div>
				<br />
				<TableComponent
					dataSource={this.props.list}
					columns={this.generateColumn()}
					id={"ExportPreviewTable"}
					paginate={true}
					pageSize={defaultPaginationSize}
					enablePageSizeChange={false}
				/>
			</div>
		);
	}
}
export default ExportPreview