import React, { Fragment, memo, useCallback, useMemo } from 'react'
import { branch, renderComponent, withProps } from 'recompose'
import classNames from 'classnames'
import { I } from '../../common/globals'
import { CLOSE_RECEIPTS } from '../../common/path'
import {
  TXT_CLOSE_RECEIPT_EMAIL_INFO,
  identity,
  noYesOptions
} from '../../common/constants'
import { TXT_ACTION } from '../../common/v5/chatbotConstants'
import {
  DEFAULT_PAGE_SIZE,
  TXT_ACTIVE,
  TXT_FOR_SOCIAL_MEDIA,
  TXT_ORGS_AREAS,
  TXT_SELECT_ORGS_AREAS
} from '../../common/v5/receiptGreetingConstants'
import { composeWithDisplayName } from '../../reactcomponents/hocs'
import { ACTION_ACTIVATE, DoubleDecks } from '../../reactcomponents/Admin'
import {
  CELL_ACTION,
  CELL_ORGS_AREAS,
  NormalizedTable,
  arrayLengthAccessors
} from '../../reactcomponents/Table'
import {
  Div,
  IdValueSingleInteger,
  NameAndEmailInput,
  ReceiptOrgAreaDropdown,
  SubjectAndMessage,
  TypeAndActiveInput,
  actionEditAndDelete,
  activeSortType,
  areasAccessor,
  hiddenColumnId,
  nameColumn,
  testEmailInvalidity,
  testEmptyReceiveName,
  testNoArraySelection,
  useEmailInvalidity,
  useEmptyReceiveName,
  useNoArraySelection,
  useOnEditField,
  ReceiptNameInput
} from './receive'

export const testNoOrgOrAreaSelection = (orgs, areas) => {
  const noArea = testNoArraySelection(TXT_ORGS_AREAS, areas)
  const noOrg = testNoArraySelection(TXT_ORGS_AREAS, orgs)
  if (noArea && noOrg) {
    return noArea
  }
}

export const dataInvalidation = ({ areas, email, name, orgs }) => {
  const emptyReceiptName = testEmptyReceiveName(name)
  const invalidEmail = testEmailInvalidity(email, true)
  const emptySelection = testNoOrgOrAreaSelection(orgs, areas)
  const result = []
  if (emptyReceiptName) {
    result.push(emptyReceiptName)
  }
  if (emptySelection) {
    result.push(emptySelection)
  }
  if (invalidEmail) {
    result.push(invalidEmail)
  }
  return result.join('. ')
}

// useBoolIntConverter convert input bool value to integer, 0 and 1.
const useBoolIntConverter = (state, onChange) => [
  state ? 1 : 0,
  useCallback(v => { onChange(v === 1) }, [onChange])
]

export const useEditBoolIntField = (state, onChange, field) => {
  const handleChange = useOnEditField(onChange, field)
  return useBoolIntConverter(state, handleChange)
}

export const useNoOrgOrAreaSelection = (orgs, areas) => {
  const noArea = useNoArraySelection(TXT_ORGS_AREAS, areas)
  const noOrg = useNoArraySelection(TXT_ORGS_AREAS, orgs)
  return useMemo(
    () => {
      if (noArea && noOrg) {
        return noArea
      }
    },
    [noArea, noOrg]
  )
}

export const useDataInvalidation = ({ areas, email, name, orgs }) => {
  const emptyReceiptName = useEmptyReceiveName(name)
  const invalidEmail = useEmailInvalidity(email, true)
  const emptySelection = useNoOrgOrAreaSelection(orgs, areas)
  return useMemo(
    () => {
      const result = []
      if (emptyReceiptName) {
        result.push(emptyReceiptName)
      }
      if (emptySelection) {
        result.push(emptySelection)
      }
      if (invalidEmail) {
        result.push(invalidEmail)
      }
      return result.join('. ')
    },
    [emptyReceiptName, emptySelection, invalidEmail]
  )
}

export const orgsAccessor = ({ original: { orgs } }) => orgs

const editDeleteActivate = actionEditAndDelete.concat([ACTION_ACTIVATE])

export const actionColumn = {
  Header: TXT_ACTION,
  accessor: 'id',
  actions: editDeleteActivate,
  disableActionsWhenRowActive: editDeleteActivate,
  id: 'active',
  propsGetter: (action, { row: { original: { active } } }) => {
    if (action === ACTION_ACTIVATE) {
      return { active }
    }
  },
  sortName: TXT_ACTIVE,
  sortType: activeSortType,
  type: CELL_ACTION
}

export const orgsAreasColumn = {
  Header: TXT_ORGS_AREAS,
  accessor: arrayLengthAccessors('orgs', 'areas'),
  areasAccessor,
  id: 'orgs_areas',
  orgsAccessor,
  type: CELL_ORGS_AREAS
}

export const columns = [
  hiddenColumnId,
  nameColumn,
  orgsAreasColumn,
  actionColumn
]

export const useClickEditDeleteActivate = onClickAction => useCallback(
  (action, id, cell) => {
    if (action === ACTION_ACTIVATE) {
      const { row: { original: { active } } } = cell
      onClickAction(action, id, active)
    } else {
      onClickAction(action, id)
    }
  },
  [onClickAction]
)

export const CommonList = ({
  SubComponent,
  activeChecker,
  byId,
  columns,
  expanded,
  hiddenColumns,
  list,
  onClickAction,
  onSortChange,
  orgArea,
  sortBy,
  subComponentProps
}) => (
  <NormalizedTable
    SubComponent={SubComponent}
    activeChecker={activeChecker}
    byId={byId}
    columns={columns}
    dataGetter={identity}
    expanded={expanded}
    hiddenColumns={hiddenColumns}
    list={list}
    onClickAction={useClickEditDeleteActivate(onClickAction)}
    onSortChange={onSortChange}
    orgArea={orgArea}
    pageSize={DEFAULT_PAGE_SIZE}
    sortBy={sortBy}
    subComponentProps={subComponentProps}
  />
)

const List = withProps({ columns })(CommonList)

export default List

export const OrgsAreasMultiSelect = ({
  onAreasChange,
  onOrgsChange,
  ...props
}) => (
  <ReceiptOrgAreaDropdown
    onSelect={useCallback(
      (selected, which) => {
        let handler
        if (which === 'area') {
          handler = onAreasChange
        } else {
          handler = onOrgsChange
        }
        handler(selected)
      },
      [onAreasChange, onOrgsChange]
    )}
    {...props}
  />
)

export const OrgsAreas = ({ onChange, ...props }) => (
  <OrgsAreasMultiSelect
    {...props}
    onAreasChange={useOnEditField(onChange, 'areas')}
    onOrgsChange={useOnEditField(onChange, 'orgs')}
  />
)

const CloseReceiptHelper = () => (
  <div>
    {I('When answering multiple email errands at the same time this together with the answer is what will be sent to the sender of those errands which were not the errand that was specifically answered.')}<br />
    {I('You can use the following templates to put customer specific information in the receipt:')}
    <ul>
      <li>
        <b>ERRANDID</b><br />
        {I('Errand number assigned to the new email.')}
      </li>
      <li>
        <b>[name]</b><br />
        {I("Customer's name. If no name was specified in incoming email then [name] will be replaced by email address.")}
      </li>
      <li>
        <b>[email]</b><br />
        {I("Customer's email address.")}
      </li>
    </ul>
    {I('Example')}:<br />
    <i>
      {I('Hi [name],')}<br />
      {I('This is an automatic repsonse that was generated when your errand {ERRANDID} was closed at the same time as another errand was answered.')}<br />
      {I('Best regards')}<br />
      [signature name]<br />
      {I('The support team')}
    </i>
  </div>
)

const SocialMediaBodyInputHelper = () => (
  <div>
    {I('When answering multiple errands at the same time and among those are both email errands and social media errands (e.g. Facebook and Twitter) and the errand specifically answered is an email errand then this is what the sender of the social media errands will receive.')}<br />
    {I('You can use the following templates to put customer specific information in the receipt:')}
    <ul>
      <li>
        <b>{'{ERRANDID}'}</b><br />
        {I('Errand number assigned to the new email.')}
      </li>
      <li>
        <b>[name]</b><br />
        {I("Customer's name. If no name was specified in incoming email then [name] will be replaced by email address.")}
      </li>
      <li>
        <b>[email]</b><br />
        {I("Customer's email address.")}
      </li>
    </ul>
    {I('Example')}:<br />
    <i>
      {I('Your question has been answered in the email {ERRANDID} you sent us. Let us know if there is anything else we can help you with.')}
    </i>
  </div>
)

const MessageHelper = composeWithDisplayName(
  'MessageHelper',
  memo,
  branch(
    ({ forSocialMedia }) => !!forSocialMedia,
    renderComponent(SocialMediaBodyInputHelper)
  )
)(CloseReceiptHelper)

export const withOrgsAreasSelection = Component => ({
  areas,
  hidden,
  onChange,
  onAreasChange,
  onOrgsChange,
  orgArea,
  orgs,
  toRight
}) => (
  <div className={classNames({ left: !toRight, right: !!toRight })}>
    <DoubleDecks
      helper={<div>{TXT_SELECT_ORGS_AREAS}</div>}
      hidden={hidden}
      text={TXT_ORGS_AREAS}
    >
      <Component
        areas={areas}
        data={orgArea}
        onAreasChange={onAreasChange}
        onChange={onChange}
        onOrgsChange={onOrgsChange}
        orgs={orgs}
      />
    </DoubleDecks>
  </div>
)

export const OrgsAreasSelection = withOrgsAreasSelection(OrgsAreas)

export const BoolYesNoSelect = ({ onSelect, selected, ...props }) => {
  const [state, handler] = useBoolIntConverter(selected, onSelect)
  return (
    <IdValueSingleInteger
      data={noYesOptions}
      onSelect={handler}
      selected={state}
      {...props}
    />
  )
}

export const Edit = ({
  data: { active, areas, content, name, email, nameInAddress, orgs, socialMedia, subject },
  isNew,
  onChange,
  onTypeChange,
  orgArea
}) => {
  return (
    // eslint-disable-next-line react/jsx-fragments
    <Fragment>
      <TypeAndActiveInput
        active={active}
        disabledReceiptType={!isNew}
        onChange={onChange}
        onTypeChange={onTypeChange}
        selected={CLOSE_RECEIPTS}
      />
      <ReceiptNameInput name={name} onChange={onChange} />
      <NameAndEmailInput
        email={email}
        nameInAddress={nameInAddress}
        onChange={onChange}
        txtEmailHelper={TXT_CLOSE_RECEIPT_EMAIL_INFO}
      />
      <SubjectAndMessage
        helper={<MessageHelper forSocialMedia={socialMedia} />}
        message={content}
        onChange={onChange}
        simpleToolbar={socialMedia}
        subject={subject}
      />
      <Div>
        <OrgsAreasSelection
          areas={areas}
          onChange={onChange}
          orgArea={orgArea}
          orgs={orgs}
        />
        <div className='right'>
          <DoubleDecks text={TXT_FOR_SOCIAL_MEDIA}>
            <BoolYesNoSelect
              onSelect={useOnEditField(onChange, 'socialMedia')}
              selected={socialMedia}
            />
          </DoubleDecks>
        </div>
      </Div>
    </Fragment>
  )
}
