import React from 'react';
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import update from 'react-addons-update';
//dev component
import TableComponent from '../../../components/TableComponent';
import DropDown from '../../../components/DropDown';
import { TableIconicButton } from '../../../reactcomponents/Form';
import { Wrapper } from '../../../reactcomponents/Admin';
import styled from 'styled-components';

//form
import OrganisationEdit from "./edit";
import AreaEdit from "../../areas/v5/edit"
import { tintDarkGrey } from "../../../styles/_variables"

const StyledWrapper = styled(Wrapper)`
#wrapper{
	width: 100%;
	padding-top: 20px;
	.row.admin-organisation-v5{
		margin: auto;

		.admin-org-list{
			.org-header-title{
				.page-header{
					margin: 0px 0px 10px;
					border-bottom: none;
				}
			}
			.org-header-button{
				#organisationFilterV5{
						&.btn-group>.btn:first-of-type{
							&:focus{
								box-shadow: none;
							}
							background: inherit;
							text-align: left;
							width: 100%;
							padding: 6px .75rem;
							font-weight: 400;
							:after{
								position: absolute;
								right: 3%;
								top: 45%;
							}
						}
						&.btn-group>.dropdown-toggle:not(:first-of-type){
							display: none;
						}
					&.open{
						.dropdown-menu{
							display: block;
							width: max-content;
							.normalSelect{
								a{
									color: inherit;
									cursor: pointer;
									font-size: 13px;
									font-weight: 400;
									padding: 3px 20px;
								}
							}
						}
					}
					
				}

				.bg-transparent {

					&:focus{
						box-shadow: none;
					}
		
					.icon-add:before{
						background-color: inherit;
						height: 20px;
						min-width: 20px;
						text-align: center;
						border-radius: 100px;
						font-size: 12px;
						font-weight: 800;
						line-height: 20px;
						padding: 5px;
					}
		
					&.active {
						.icon-add:before{
							background-color: #7ac0ff4f;
							color: #0c87f7;
						}
					}
				}
			}

			.org-table{
				#organisationsTable, #organisationsTable th, #organisationsTable td{
					border: none;
		
					thead th, tbody tr{
						border: none;
						border-bottom: 1px solid #00000033;
						background-color: transparent;
					}
		
					thead th {
						&.Action{
							text-align: center !important;
						}
					}
		
					tbody tr td {
						vertical-align: middle;
						.table-btns button{
							padding: 1px 4px;
							font-size: 16px;
						}
						.table-btns .situationalAdd{
							width: 23px;
							display: inline-block;
						}
						&.organisation-row{
							font-weight: 700;
						}
						&.area-row{
							font-weight: 300;
						}
					}
				}
				.col-md-12.pagination-row{
					display: flex;
					justify-content: space-between;
					.currentPage{
						margin-right: 10px;
					}
					.currentPage , .display-paging{
						display: inline-flex;
						padding-bottom: 15px;
					}
					.display-paging span{
						padding-top: 0px;
					}
					.display-paging .page-arrow{
						line-height: 1.3;
					}
				}
			}

		}

		.admin-org-edit{
			.panel .panel-body{
				margin-bottom: 20px;
				margin-top: 10px;
				border: none;
				box-shadow: 0 3px 8px 1.5px #7ac0ff82;
				position: relative;
				display: -ms-flexbox;
				display: flex;
				-ms-flex-direction: column;
				flex-direction: column;
				min-width: 0;
				word-wrap: break-word;
				// background-color: #fff;
				background-clip: border-box;
				border: 1px solid rgba(0,0,0,.125);
				border-radius: .25rem;
				padding: 20px;

				.org-nav-tabs{
					height: 35px;
					border-bottom: none;
					margin-bottom: 10px;
					.nav-item{
						margin-bottom: 10px;
						a {
							font-size: 12px;
							padding: .5rem .5rem;
							// color: #495057;
							border: 1px solid transparent;
							&.active{
								border-bottom-color: #0c87f7;
								border-width: 0 0 thick 0;
								// color: black;
								font-weight: 500;
							}
						}

						&.nav-page-toggle{
							position: absolute;
							right: 1%;
						}
					}
				}

				span.fa-stack{
					line-height: 1em;
					font-size: 14px;
					width: 1em;
					margin-top: 5px;
				}

				.toggle-switch{
					margin-top: 5px;
				}

				.label-block{
					width: 100%;
					.select-row{
						display: inline-flex;
						width: 100%;
						.jq-selectbox{
							padding: 0px;
							display: inline-block;
							height: 32px;
							width: 100%;
							.jq-selectbox__select{
								width: 100%;
								height: 32px;
								line-height: 30px;
								.jq-selectbox__select-text{
									width: 100%;
									height: 32px;
									padding-right: 20px;
									color: #6d6d6d;
									font-size: 12px;
									font-weight: 500;
									outline: 0;
									-webkit-transition: border-color .2s ease;
									-o-transition: border-color .2s ease;
									-moz-transition: border-color .2s ease;
									transition: border-color .2s ease;
								}
								.jq-selectbox__trigger{
									font-size: 11px;
								}
							}
							.jq-selectbox__dropdown{
								max-height: 300px!important;
								z-index: 1;
								ul li{
									font-size: 12px;
								}
							}
						}
					}
				}
				
				.dot-row{
					text-align: center;
					.dot{
						cursor: pointer;
						margin-right: 5px;
						height: 7px;
						width: 7px;
						background-color: #eaeaea;
						border-radius: 50%;
						display: inline-block;
						&.active{
							background-color: #25ADFC;
						}
					}
				}

				.tab-pane{
					font-size: 12px;

					.area-form{
						margin: 0px -15px;
						
						.select-row{
							.col-lg-12, .col-lg-6{
								padding-right: 0px;
							}
						}
						.label-block{
							width: 100%;
							.select-row{
								display: inline-flex;
								width: 100%;
								.jq-selectbox{
									padding: 0px;
									display: inline-block;
									height: 32px;
									width: 100%;
									.jq-selectbox__select{
										width: 100%;
										height: 32px;
										line-height: 30px;
										.jq-selectbox__select-text{
											width: 100%;
											height: 32px;
											padding-right: 20px;
											color: #6d6d6d;
											font-size: 12px;
											font-weight: 500;
											outline: 0;
											-webkit-transition: border-color .2s ease;
											-o-transition: border-color .2s ease;
											-moz-transition: border-color .2s ease;
											transition: border-color .2s ease;
										}
										.jq-selectbox__trigger{
											font-size: 11px;
										}
									}
									.jq-selectbox__dropdown{
										max-height: 300px!important;
    									z-index: 1;
										ul li{
											font-size: 12px;
										}
									}
								}
							}
						}
						.field-row {
							.td-padding-right{
								td{
									width: 50px;
								}
							}
							.row{
								margin-left: 0px;
								.col-lg-6{
									padding-left: 0px;
								}
								.col-lg-12{
									padding-right: 0px;
								}
							}
						}

						.margin-collapse-row.row{
							margin-left: 0px;
						}

						.c3-ckeditor{
							border: thin solid #e6e6e6;
							.cke_top{
								border: none;
							}
							a.cke_button{
								padding: 1px 3px;
							}
						}

						.satisfaction-feedback{
							input{
								width: 30%;
								display: inline;
								margin-right: 5px;
							}
						}

						.blackout-period-after-sent-survey{
							input{
									width: 30%;
									display: inline;
									margin-right: 5px;
							}
						}

						.due-date, #due_date{
							width: 100%;
							.area-dropdown-v5{
								width: auto;
								float: left;
								padding-left: 15px;
								padding-right: 15px;
							}
							#areaDropdownV5{
								border: 1px solid #e6e6e6;
								&.btn-group>.btn:first-of-type{
									&:focus{
										box-shadow: none;
									}
									background: inherit;
									text-align: left;
									width: 100%;
									padding: 6px .75rem;
									font-weight: 400;
									:after{
										position: absolute;
										right: 3%;
										top: 45%;
									}
								}
								&.btn-group>.dropdown-toggle:not(:first-of-type){
									display: none;
								}
								&.open{
									.dropdown-menu{
										display: block;
										width: max-content;
										.normalSelect{
											a{
												color: inherit;
												cursor: pointer;
												font-size: 13px;
												font-weight: 400;
												padding: 3px 20px;
											}
										}
									}
								}
								
							}
						}
						.agent-list-dropdown{
							.list-multiselect{
								position: relative;
								top: 100%;
								left: 0;
								display: block;
								height: 200px;
								overflow: auto;
								padding: 5px 20px;
								margin: 2px 0 0;
								text-align: left;
								list-style: none;
								background-color: inherit;
								-webkit-background-clip: padding-box;
								background-clip: padding-box;
								border: 1px solid rgba(0,0,0,.125);
								border-radius: 4px;
								-webkit-box-shadow: 0 3px 8px 1.5px #7ac0ff82;
								box-shadow: 0 3px 8px 1.5px #7ac0ff82;
							}
							.dropdown-options{
								margin-top: 5px;
							}
							.btn-group-vertical {
								position: relative;
								display: inline-block;
								vertical-align: middle;
								text-align: center;
								a{
									box-shadow: 0 5px 5px 0.5px #7ac0ff82;
									border-color: transparent;
									margin-right: 5px;
									color: #25ADFC;
									font-weight: 200;
									font-size: 13px;
									width: auto;
								}
							}
							.btn {
								display: inline-block;
								padding: 6px 6px;
								margin-bottom: 0;
								font-size: 10px;
								font-weight: 400;
								line-height: 1.42857143;
								text-align: center;
								white-space: nowrap;
								vertical-align: middle;
								-ms-touch-action: manipulation;
								touch-action: manipulation;
								cursor: pointer;
								-webkit-user-select: none;
								-moz-user-select: none;
								-ms-user-select: none;
								user-select: none;
								background-image: none;
								border: 1px solid transparent;
								border-radius: 4px;
							}
							.btn-default {
								color: inherit;
								border-color: #ccc;
							}
							.dropdown-options{
								text-align: right;
							}
						}
					}

					.form-group-org{
						width: auto;
						display: block;
						padding: 10px 15px 10px 15px;
						.textAlignRight{
							text-align: right;
						}
						.hintTextClass{
							padding: 6px 6px;
							&:after{
								display: none;
							}
						}
					}
					.form-control{
						font-size: 12px;
					}
					
					.editPageActionButton {
						.textAlignRight {
							margin-top: 8px;
							text-align: right;
							.btn {
								margin: 0px 1.5px;
								color: white;
							}
						}
						.row{
							display: block;
						}
						.textAlignLeft{
							margin-top: 8px;
							text-align: left;
						}
					}

					.working-hours{
						.leftside-bar{
							width: 100%;
							table.table{
								thead th{
									text-align: center;
								}
								tbody{
									td,th{
										vertical-align: baseline;
										border-bottom: 1px solid #00000033;
									}
									tr{
										text-align: center;
									}
									td{
										padding: 1.2rem .75rem 1.2rem .75rem;
										span a{
											color: #25ADFC;
										}
									}
									label.toggle-switch{
										margin-bottom: 0px;
									}
								}
							}
							.setting-icons{
								font-size: 10px;
								margin-left: 20px;
								display: inline-flex;
								vertical-align: bottom;
								.icon-settings{
									font-size: 14px;
									padding-right: 5px;
								}
								.setting-text{
									font-weight: bold;
								}
							}
						}
						.rightside-bar{
							width: 100%;
							.rbc-calendar{
								display: block;
								.rbc-header{
									color: #25ADFC;
									min-height: 20px;
									span{
										vertical-align: middle;
									}
								}
								.rbc-row-content{
									min-height: 60px;
								}
								.rbc-event{
									background-color: #25ADFC;
									border-radius: 0px;
									border: 1px solid #25ADFC
								}
								.rbc-today{
									background-color: #E2FBFE;
								}
								.rbc-toolbar-label{
									position: absolute;
									right: 1%;
									font-size: 12px;
								}
								.rbc-btn-group{
									display: -webkit-box;
									display: -moz-box;
									display: -ms-flexbox;
									display: -webkit-flex;
									display: flex;
									-webkit-box-orient: horizontal;
									-moz-box-orient: horizontal;
									-webkit-flex-direction: inherit;
									-ms-flex-direction: inherit;
									flex-direction: inherit;
									/* optional */
									-webkit-box-align: start;
									-moz-box-align: start;
									-ms-flex-align: start;
									-webkit-align-items: flex-start;
									align-items: flex-start;

									#button-1, #button-2, #button-3{
										padding: .375rem;
										box-shadow: 0 5px 5px 0.5px #7ac0ff82;
										border-color: transparent;
										margin-right: 5px;
										color: #25ADFC;
										font-weight: 200;
										font-size: 13px;
									}
									#button-1{
										-webkit-box-ordinal-group: 1;
										-moz-box-ordinal-group: 1;
										-ms-flex-order: 1;
										-webkit-order: 1;
										order: 1;
									}
									#button-2{
										-webkit-box-ordinal-group: 0;
										-moz-box-ordinal-group: 0;
										-ms-flex-order: 0;
										-webkit-order: 0;
										order: 0;
									}
									#button-3{
										-webkit-box-ordinal-group: 2;
										-moz-box-ordinal-group: 2;
										-ms-flex-order: 2;
										-webkit-order: 2;
										order: 2;
									}
									#button2-1,#button2-2{
										font-size: 11px;
										padding: .375rem;
										margin-top: 1px;
										border: 1px solid transparent;
										border-width: 0 0 3px 0;
										&.rbc-active{
											border-color: #fff #fff #25ADFC #fff;
											border-radius: 0px;
											background-color: transparent;
											box-shadow: none;
										}
										&:focus{
											box-shadow: none;
											outline-color: transparent;
											outline-style: none;
										}
									}
									#button2-1{
										-webkit-box-ordinal-group: 2;
										-moz-box-ordinal-group: 2;
										-ms-flex-order: 2;
										-webkit-order: 2;
										order: 2;
									}
									#button2-2{
										-webkit-box-ordinal-group: 1;
										-moz-box-ordinal-group: 1;
										-ms-flex-order: 1;
										-webkit-order: 1;
										order: 1;
									}
								}
								.rbc-time-view .rbc-row-content{
									display: none;
								}
							}
							.modal-wrapper {
								background: #fff;
								box-shadow: 0 3px 8px 4.5px #e6e6e6;
								padding: 10px;
								border: none;
								.modal-item{
									align-self: auto;
								}
								.working-hour-table{
									background: #fff;
									color: #333;
									.table-header{
										text-align: center;
										td{
											justify-content: center;
										}
									}
									tr td{
										display: flex;
										justify-content: space-between;
										.toggle-switch{
											margin-top: 0px;
										}
										border-top: none;
									}
								}
								.table td, .table th{
									padding: .35rem;
								}
							}
						}
					}
					.external-system-settings, .area-edit-settings{
						.form-control{
							font-weight: 400;
							color: #333;
						}

						.table-wrappers{
							width: 100%;
							#smppAreaAccountsTable{
								.toggle-switch{
									margin-top: 0px;
								}
							}
							.organisation-row{
								vertical-align: middle;
							}
						}
						.table-bordered, .table-bordered th, .table-bordered td{
							border: none;
						}
						.table-bordered thead th, .table-bordered tbody tr{
							border: none;
							border-bottom: 1px solid #D5D5D5;
							background-color: transparent;
						}
						.table-bordered thead th {
							&.Action{
								text-align: center !important;
							}
						}
						.icon-add{
							color: #25ADFC;
						}

						.MultiPurposeDropdown{
							border: 1px solid ${tintDarkGrey};
							&.autotag-v5{
								#comboStaticundefined{
									display: none;
								}
								.dropdown-menu{
									width: auto;
									left: -100px;
								}
							}
							&.btn-group>.btn:first-of-type{
								&:focus{
									box-shadow: none;
								}
								background: inherit;
								text-align: left;
								width: auto;
								padding: 6px 2rem 6px 3px;
								font-weight: 400;
								:after{
									position: absolute;
									right: 3%;
									top: 45%;
								}
							}
							&.btn-group>.dropdown-toggle:not(:first-of-type){
								display: none;
							}
							&.open{
								.dropdown-menu{
									display: block;
									width: max-content;
									.normalSelect{
										a{
											color: inherit;
											cursor: pointer;
											font-size: 13px;
											font-weight: 400;
											padding: 3px 20px;
										}
									}
								}
							}
							
						}

						#smpp-edit-modal{
							display: none; 
							position: fixed;
							z-index: 1000;
							padding-top: 100px;
							left: 0;
							top: 0;
							width: 100%;
							height: 100%;
							overflow: auto;
							background-color: rgb(0,0,0);
							background-color: rgba(0,0,0,0.3);
							#areaSmppAccountEditForm{
								margin: auto;
								display: block;
								width: 50%;
								max-width: 40%;
								position: absolute;
								right: 20px;
								padding: 0;
								.panel-body{
									padding: 30px 30px;
								}
							}
						}
					}
				}
			}	
		}
	}
}

`

var OrganisationsList = createReactClass({
	getInitialState: function () {
		return {
			filterType: "Active",
			tds: [],
			features: {},
			orgList: [],
			editOrg: false,
			orgId: 0,
			editId: 0,
			areaId: 0,
			hasRun: 0,
			errorMessage: "",
			editArea: false,
			showFilter: false
		}
	},
	componentDidMount: function () {
		if (this.props.data.list !== undefined) {
			var tds = this.generateTableDataSource(this.props.data.list);
			this.setState({
				tds: tds,
				orgList: this.props.data.list
			});
		}
	},
	handleAreaDropDown: function (item, itemName) {
		if (itemName == "Choose") {
			return
		}
		var url = pathPrefix + "admin#areas/";
		if (itemName == "Routing") {
			url += item.Id + "/keywords/list";
		} else if (itemName == "Replacements") {
			url += item.Id + "/replacements/list";
		} else if (itemName == "AutoFwdExtAddr") {
			url += item.Id + "/autofwdextaddr/list";
		} else if (itemName == "SMS") {
			url += item.Id + "/smppaccounts/list";
		} else if (itemName == "ExtFwdAddr") {
			url += item.Id + "/externalforwardaddress/list";
		}
		location.href = window.location.protocol + "//" +
			window.location.hostname + url;
	},
	handleAreaClick: function (item, ev) {
		this.renderAreaEdit(item.Id);
	},
	handleOrgClick: function (item, ev) {
		this.renderOrg(item.Id);
	},
	handleRowClick: function (item, ev) {
		if (typeof item.clickFunc !== "undefined") {
			item.clickFunc(item, ev);
		}
	},
	handleActivateOrg: function (item, ev) {
		var putData = { action: "activate" };
		$.ajax({
			url: window.location.protocol + "//" + window.location.hostname +
			process.env.PATH_PREFIX + "admin/organisations/" + item.Id,
			type: "PUT",
			data: putData
		}).done(function (data) {
			this.props.onLoadTable();
		}.bind(this));
	},
	handleDeactivateOrg: function (item, ev) {
		var putData = { action: "deactivate" };
		$.ajax({
			url: window.location.protocol + "//" + window.location.hostname +
			process.env.PATH_PREFIX + "admin/organisations/" + item.Id,
			type: "PUT",
			data: putData
		}).done(function (data) {
			this.props.onLoadTable();
		}.bind(this));
	},
	handleActivateArea: function (item, ev) {
		var putData = { action: "activate" };
		$.ajax({
			url: window.location.protocol + "//" + window.location.hostname +
			process.env.PATH_PREFIX + "admin/organisations/0/area/" +
				item.Id,
			type: "PUT",
			data: putData
		}).done(function (data) {
			this.props.onLoadTable();
		}.bind(this));
	},
	handleDeactivateArea: function (item, ev) {
		if (confirm(I("Deactivate this area?"))) {
			var putData = { action: "deactivate" };
			$.ajax({
				url: window.location.protocol + "//" + window.location.hostname +
				process.env.PATH_PREFIX + "admin/organisations/0/area/" +
					item.Id,
				type: "PUT",
				data: putData
			}).done(function (data) {
				this.props.onLoadTable();
			}.bind(this));
		}
	},
	filterThis: function (theStatus) {
		if (this.state.filterType == "Active" && theStatus == false) {
			return true;
		}
		if (this.state.filterType == "Inactive" && theStatus == true) {
			return true;
		}
		return false;
	},
	handleSort: function (list) {
		if (list) {
			list.sort(function (a, b) {
				return a.Name.toLowerCase().localeCompare(b.Name.toLowerCase(), undefined, {
					numeric: true
				});
			})
		}
		return list;
	},
	handleToggleOrg: function () {
		this.setState({ showFilter: !this.state.showFilter });
	},
	generateTableDataSource: function (orgList) {
		var tableDataSource = [];
		if (orgList == null) {
			return;
		}
		orgList.forEach(function (org) {
			org["id"] = org.Id;
			if (org.Areas && org.Areas.length > 0) {
				var areaArr = [];
				org.Areas.forEach(function (area) {
					if (this.filterThis(area.Active)) {
						return;
					}
					var name = I("Deactivate");
					var action = this.handleDeactivateArea;
					if (area.Active == false) {
						name = I("Activate");
						action = this.handleActivateArea;
					}
					area["id"] = area.Id;
					area["subButton"] = {
						action: action,
						name: name,
						className: "fa fa-remove"
					};
					area["subType"] = "dropdown";
					area["actionFunc"] = this.handleAreaDropDown;
					area["clickFunc"] = this.handleAreaClick;
					areaArr.push(area);
				}.bind(this));
				org["childList"] = areaArr;
			}
			var name = I("Deactivate");
			var action = this.handleDeactivateOrg;
			if (org.Active == false) {
				name = I("Activate");
				action = this.handleActivateOrg;
			}
			org["subButton"] = {
				action: action,
				name: name,
				className: "fa fa-remove"
			};
			org["subType"] = "button";
			org["clickFunc"] = this.handleOrgClick;
			tableDataSource.push(org);
		}.bind(this));
		this.handleSort(tableDataSource)
		return tableDataSource;
	},
	renderOrg: function (id) {
		if (this.props.renderEditForm) {
			this.props.renderEditForm(id, true);
		}
		this.setState({
			editOrg: true
		});
	},
	handleEdit: function (id, type) {
		if (type.subType && type.subType == "dropdown") {
			this.renderAreaEdit(id);
		} else {
			this.renderOrg(id);
		}

	},
	createNewOrg: function () {
		this.renderOrg(0);
	},
	renderAreaEdit: function (id, orgId) {
		if (this.props.renderAreaEditForm) {
			this.props.renderAreaEditForm(id, orgId, true);
		}
		this.setState({
			editArea: true
		});
	},
	handleOrgListFilter: function (e) {
		var tds = this.generateTableDataSource(this.state.orgList);
		this.setState({
			tds: tds,
			filterType: e
		});
	},
	handleAddArea: function (data) {
		this.props.reRenderAreaEdit(0, data);
	},
	render: function () {
		var CreateAreaButton = {
			action: "#areas/0/org/{id}",
			name: I("Create Area"),
			actionType: "redirect",
			color: "black",
			bgColor: "#D3D3D3",
			width: "13%"
		};
		var tds = this.state.tds;
		var defaultPaginationSize = 13;
		var activeButton = this.state.editOrg ? "active" : "";
		var dropdownParams = {
			typeItems: "dropdown",
			actionFunc: this.handleDropdown
		};
		var colData = { buttonAction: CreateAreaButton, dropdown: dropdownParams };
		var column = [
			{ header: I("Name"), key: "Name", type: "rowExpandWithItem" },
			{ header: I("Action"), type: 'actionCombineLink', data: colData, key: 'action', id: 'id', notClickable: true, centerAlign: true }
		];
		var filterItems = [
			{ id: "Active", field: I("Active") },
			{ id: "Inactive", field: I("Inactive") },
			{ id: "All", field: I("All") }
		];
		if(this.props.data.list !== undefined){
			tds = this.generateTableDataSource(this.props.data.list);
		}
		return (
			<div id="organisation-list-wrapper" key={this.state.filterType}>
				<div className="row org-header">
					<div className="col-lg-6 org-header-title">
						<h3>{I('Organizations and areas')}</h3>
					</div>
					<div className="col-lg-6 org-header-button">
						<label className="col-lg-8" style={{ textAlign: "right" }} htmlFor="basic">
							<DropDown
								id="organisationFilterV5"
								name={"Filter"}
								selectedItems={this.state.filterType}
								items={filterItems}
								fields={{ id: "id", name: "field" }}
								multiSelect={false}
								wantSelectAll={false}
								wantSelectNone={false}
								wantShowDone={false}
								onChange={this.handleOrgListFilter}
								sort={false}
							/>
						</label>
						<div className="col-md-4" style={{ textAlign: "right" }}>
							<TableIconicButton
								type='button'
								className={'btn bg-transparent btn-round ' + activeButton}
								iconClass='icon-add'
								title={I('Create new')}
								onClick={() => this.createNewOrg()}
							/>
						</div>
					</div>
				</div>
				<div className="row org-table">
					<div className="col-lg-12">
						<TableComponent
							dataSource={tds}
							columns={column}
							id={'organisationsTable'}
							paginate={true}
							pageSize={defaultPaginationSize}
							enablePageSizeChange={false}
							altOnClickHandler={this.handleRowClick}
							onEditActionRow={this.handleEdit}
							onAddActionRow={this.handleAddArea}
							organisationV5={true}
						/>
					</div>
				</div>
			</div>
		);
	},
});





var Organisations = createReactClass({
	getInitialState: function () {
		return {
			orgList: [],
			editId: 0,
			orgId: 0,
			areaEditId: 0,
			editOrg: false,
			editArea: false,
			renderOrgList: true,
			reRender: false
		}
	},
	componentWillMount: function () {
		this.props.onLoadTableList();
	},
	handleReloadTableList: function() {
		this.props.onLoadTableList();
	},
	handleRenderOrganisation: function(id){
		this.props.getOrganisation(id);
	},
	handleRenderEditForm: function (id, editOrg) {
		if (this.state.editId !== id) {
			this.reRenderEdit(id);
		} else {
			this.setState({
				editId: id,
				editOrg: editOrg,
				editArea: false
			});
		}
	},
	handleRenderAreaEdit: function (id, orgId, editArea) {
		if (this.state.areaEditId !== id) {
			this.reRenderAreaEdit(id, orgId);
		} else {
			this.setState({
				areaEditId: id,
				editArea: editArea,
				editOrg: false,
				orgId: orgId ? orgId : 0
			});
		}
	},
	handleCloseEditForm: function () {
		this.setState({
			editOrg: false,
			editArea: false
		}, () => {
			this.handleReloadTableList();
		})
	},
	handleExternal: function (p){
		this.props.saveExternal(p);
	},
	reRenderList: function (){
		this.setState({
			renderOrgList: true
		})
	},
	reRenderEdit: function (id) {
		this.setState({
			editId: id,
			editOrg: false
		}, () => {
			this.handleRenderEditForm(id, true);
		});
	},
	reRenderAreaEdit: function (id, orgId) {
		this.setState({
			areaEditId: id,
			editArea: false
		}, () => {
			this.handleRenderAreaEdit(id, orgId, true);
		});
	},
	renderList: function () {
		if(this.state.renderOrgList)
			return <OrganisationsList data={this.props.orgList} onLoadTable={this.handleReloadTableList} renderEditForm={this.handleRenderEditForm} renderAreaEditForm={this.handleRenderAreaEdit} reRenderAreaEdit={this.reRenderAreaEdit}/>;
	},
	renderEdit: function () {
		if (this.state.editOrg) {
			return <OrganisationEdit data={this.props.org} id={this.state.editId} onCloseEdit={this.handleCloseEditForm} renderOrg={this.handleRenderOrganisation} saveExternal={this.handleExternal}/>
		}
		if (this.state.editArea) {
			return <AreaEdit
				id={this.state.areaEditId}
				orgId={this.state.orgId}
				onCloseEdit={this.handleCloseEditForm}
				onLoadAreaSkillsTab={this.props.onLoadAreaSkillsTab}
				skillList={this.props.skillList}
				areaSkills={this.props.areaSkills}
				proficiencyList={this.props.proficiencyList}
				onSaveAreaSkill={this.props.onSaveAreaSkill}
				onDeleteAreaSkill={this.props.onDeleteAreaSkill}
				adminActionAlert={this.props.adminActionAlert}
				onDismissAlert={this.props.onDismissAlert}
			/>
		}
		return null;
	},
	render: function () {
		let renderEditOrgForm = this.renderEdit();
		let renderListOrg = this.renderList();
		return (
			<StyledWrapper id="organisationWrapper">
				<div id="wrapper">
					<div id="page-wrapper">
						<div className="row admin-organisation-v5">
							<div className="col-lg-6 admin-org-list">
								{renderListOrg}
							</div>
							<div className="col-lg-6 admin-org-edit">
								{renderEditOrgForm}
							</div>
						</div>
					</div>
				</div>
			</StyledWrapper>
		);
	},
});

export default Organisations;
