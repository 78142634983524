import React, { Fragment, memo, useMemo } from 'react'
import { withProps } from 'recompose'
import { GREETINGS } from '../../common/path'
import {
  TXT_AREAS,
  TXT_MESSAGE,
  TXT_NAME,
  TXT_GREETINGS_TYPE,
  GREET_OPT_CHAT_AUTO_REPLY,
  GREET_OPT_OUTSIDE_WORKING,
  normGreetOption
} from '../../common/v5/receiptGreetingConstants'
import { composeWithDisplayName } from '../../reactcomponents/hocs'
import { DoubleDecks } from '../../reactcomponents/Admin'
import Input from '../../reactcomponents/Input'
import {
  AreasSelection,
  Div,
  InputName2,
  TypeAndActiveInput,
  areasColumn,
  hiddenColumnId,
  nameColumn,
  testInvalidEmptyDataField,
  testNoArraySelection,
  useInvalidEmptyDataField,
  useNoArraySelection,
  useOnEditField
} from './receive'
import {
  SimpleNormSingleSelect
} from '../../reactcomponents/Dropdown'
import { CommonList, actionColumn } from './close'

export const dataInvalidation = ({ areas, name }) => {
  const noArea = testNoArraySelection(TXT_AREAS, areas)
  const emptyName = testInvalidEmptyDataField(TXT_NAME, name)
  const result = []
  if (emptyName) {
    result.push(emptyName)
  }
  if (noArea) {
    result.push(noArea)
  }
  return result.join('. ')
}

export const useDataInvalidation = ({ areas, name }) => {
  const noArea = useNoArraySelection(TXT_AREAS, areas)
  const emptyName = useInvalidEmptyDataField(TXT_NAME, name)
  return useMemo(
    () => {
      const result = []
      if (emptyName) {
        result.push(emptyName)
      }
      if (noArea) {
        result.push(noArea)
      }
      return result.join('. ')
    },
    [emptyName, noArea]
  )
}

export const columns = [
  hiddenColumnId,
  nameColumn,
  areasColumn,
  actionColumn
]

const List = composeWithDisplayName(
  'List',
  memo,
  withProps({ columns })
)(CommonList)

export default List

export const Edit = ({
  data: { active, areas, content, name, types },
  isNew,
  onChange,
  onTypeChange,
  orgArea
}) => (
  // eslint-disable-next-line react/jsx-fragments
  <Fragment>
    <TypeAndActiveInput
      active={active}
      disabledReceiptType={!isNew}
      onChange={onChange}
      onTypeChange={onTypeChange}
      selected={GREETINGS}
    />
    <Div>
      <div className='left'>
        <InputName2 name={name} onChange={onChange} title={TXT_NAME} />
      </div>
      <div className='right'>
        <DoubleDecks text={TXT_MESSAGE}>
          <Input
            onChange={useOnEditField(onChange, 'content')}
            value={content}
          />
        </DoubleDecks>
      </div>
    </Div>
    <Div>
      <div className='left'>
        <DoubleDecks
          text={TXT_GREETINGS_TYPE}
        >
          <SimpleNormSingleSelect
            data={normGreetOption}
            onSelect={useOnEditField(onChange, 'types')}
            selected={types === GREET_OPT_CHAT_AUTO_REPLY ? GREET_OPT_CHAT_AUTO_REPLY: GREET_OPT_OUTSIDE_WORKING}
          />
        </DoubleDecks>
      </div>
    </Div>
    <Div>
      <AreasSelection
        areas={areas}
        onChange={onChange}
        data={orgArea}
      />
    </Div>
  </Fragment>
)
