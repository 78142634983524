import React, { Fragment, memo, useCallback, useMemo, useState } from 'react'
import update from 'immutability-helper'
import styled from 'styled-components'
import { withProps } from 'recompose'
import each from 'lodash/each'
import { I } from '../../common/globals'
import { RECEIVE_RECEIPTS } from '../../common/path'
import { doNothing, identity } from '../../common/constants'
import { ADMIN_CKE_DEF_HEIGHT } from '../../common/v5/constants'
import { reEmail } from '../../common/helpers'
import {
  AEO_EDIT,
  AEO_MANUAL,
  TXT_ACTION,
  TXT_NAME
} from '../../common/v5/chatbotConstants'
import {
  DEFAULT_PAGE_SIZE,
  DT_OPT_CUSTOM,
  DT_OPT_OUT_OF_CUSTOM,
  TXT_ACCOUNTS,
  TXT_ACTIVATE_INACTIVE_THIS_RECEIPT,
  TXT_ACTIVE,
  TXT_AREAS,
  TXT_BOTH_FIELDS_EMPTY,
  TXT_DATE,
  TXT_END_DATE,
  TXT_END_TIME,
  TXT_FIELD_NAME_EMPTY,
  TXT_INVALID_EMAIL,
  TXT_NO_SELECTION,
  TXT_NUMBER_DAYS,
  TXT_RECEIPT_ADDRESS,
  TXT_RECEIPT_FROM_EMAIL,
  TXT_RECEIPT_MESSAGE,
  TXT_RECEIPT_NAME,
  TXT_RECEIPT_ADDRESS_NAME,
  TXT_RECEIPT_SUBJECT,
  TXT_RECEIPT_TYPE,
  TXT_REOCCURENCE,
  TXT_SELECT_RECEIPT_TYPE,
  TXT_SELECT_ACCOUNTS,
  TXT_SELECT_AREAS,
  TXT_SELECT_TIME_OPTION,
  TXT_START_DATE,
  TXT_START_TIME,
  TXT_TIME,
  TXT_UNSUPPORTED_FROM_MORE_THAN_TO,
  normDateOption,
  normTimeOption,
  normType,
  reoccurenceOption
} from '../../common/v5/receiptGreetingConstants'
import { inputBorderRadius } from '../../styles/_variables'
import {
  useCallbackMultiValues,
  useCallbackWithValue,
  useToggleBool
} from '../../hooks/callback'
import {
  composeWithDisplayName,
  omitProps,
  withUnmountWhenHidden
} from '../../reactcomponents/hocs'
import {
  ACTION_ACTIVATE,
  ACTION_DELETE,
  ACTION_EDIT,
  DoubleDecks
} from '../../reactcomponents/Admin'
import { focusedBorderStyled } from '../../reactcomponents/Button'
import { DateWithoutYear, TimePicker } from '../../reactcomponents/DatePicker'
import {
  IdValueSingleSelect,
  NormSelectDropdown,
  OrgAreaDropdown,
  SimpleNormSingleSelect,
  useCountAsSelectedText,
  isInitialIntegerSelection
} from '../../reactcomponents/Dropdown'
import Input from '../../reactcomponents/Input'
import { PlainSwitchCheckbox } from '../../reactcomponents/SwitchCheckbox'
import {
  CELL_ACTION,
  CELL_ACCOUNTS,
  CELL_AREAS,
  CELL_SWITCH,
  NormalizedTable,
  arrayLengthAccessors,
  combineCells
} from '../../reactcomponents/Table'
import Ckeditor, { useContentChange } from '../../components/v5/Ckeditor'
import { withCkeditor } from '../../containers/receiptGreeting'
import { tintGrey } from '../../styles/_variables'

export const IdValueSingleInteger = composeWithDisplayName(
  'IdValueSingleInteger',
  memo,
  withProps({ isInitializedSelection: isInitialIntegerSelection })
)(IdValueSingleSelect)

export const testEmailInvalidity = (email, allowEmpty) => {
  if ((!allowEmpty || email) && !reEmail.test(email)) {
    return TXT_INVALID_EMAIL
  }
}

const testInvalidEmail = email => testEmailInvalidity(email)

export const useEmailInvalidity = (email, allowEmpty) => useMemo(
  () => testEmailInvalidity(email, allowEmpty),
  [email, allowEmpty]
)

const useInvalidEmail = email => useEmailInvalidity(email)

const testEmptyDataField = (field, data, emptyChecker) => {
  if (emptyChecker(data)) {
    return TXT_FIELD_NAME_EMPTY.replace('{FIELD_NAME}', field)
  }
}

export const testInvalidEmptyDataField = (
  field,
  data
) => testEmptyDataField(field, data, data => !data)

export const testEmptyReceiveName = name => testInvalidEmptyDataField(
  TXT_RECEIPT_NAME,
  name
)

export const testNoArraySelection = (
  field,
  array
) => testEmptyDataField(field, array, array => !array || !array.length)

const isEmptyObjectOfArray = object => {
  if (!object) {
    return true
  }
  let notEmpty
  each(object, array => {
    if (array.length) {
      notEmpty = true
      return false
    }
  })
  return !notEmpty
}

const testNoObjectOfArraySelection = (
  field,
  array
) => testEmptyDataField(field, array, isEmptyObjectOfArray)

const testNoAreaNoAccount = (noArea, noAccount) => {
  if (noArea && noAccount) {
    return TXT_BOTH_FIELDS_EMPTY
      .replace('{FIRST_FIELD}', TXT_AREAS)
      .replace('{SECOND_FIELD}', TXT_ACCOUNTS)
  }
}

const testNoAreaOrAccountSelection = (areas, accounts) => {
  const noArea = testNoArraySelection(TXT_AREAS, areas)
  const noAccount = testNoObjectOfArraySelection(TXT_ACCOUNTS, accounts)
  return testNoAreaNoAccount(noArea, noAccount)
}

export const dataInvalidation = ({ accounts, areas, email, name }) => {
  const emptyReceiptName = testEmptyReceiveName(name)
  const invalidEmail = testInvalidEmail(email)
  const emptySelection = testNoAreaOrAccountSelection(areas, accounts)
  const result = []
  if (emptyReceiptName) {
    result.push(emptyReceiptName)
  }
  if (emptySelection) {
    result.push(emptySelection)
  }
  if (invalidEmail) {
    result.push(invalidEmail)
  }
  return result.join('. ')
}

const useEmptyDataField = (field, data, emptyChecker) => useMemo(
  () => testEmptyDataField(field, data, emptyChecker),
  [data, emptyChecker, field]
)

export const useInvalidEmptyDataField = (
  field,
  data
) => useEmptyDataField(field, data, data => !data)

export const useEmptyReceiveName = name => useInvalidEmptyDataField(
  TXT_RECEIPT_NAME,
  name
)

export const useNoArraySelection = (
  field,
  array
) => useEmptyDataField(field, array, array => !array || !array.length)

const useNoObjectOfArraySelection = (
  field,
  array
) => useEmptyDataField(field, array, isEmptyObjectOfArray)

const useNoAreaOrAccountSelection = (areas, accounts) => {
  const noArea = !!useNoArraySelection(TXT_AREAS, areas)
  const noAccount = !!useNoObjectOfArraySelection(TXT_ACCOUNTS, accounts)
  return useMemo(
    () => testNoAreaNoAccount(noArea, noAccount),
    [noArea, noAccount]
  )
}

export const useDataInvalidation = ({ accounts, areas, email, name }) => {
  const emptyReceiptName = useEmptyReceiveName(name)
  const invalidEmail = useInvalidEmail(email)
  const emptySelection = useNoAreaOrAccountSelection(areas, accounts)
  return useMemo(
    () => {
      const result = []
      if (emptyReceiptName) {
        result.push(emptyReceiptName)
      }
      if (emptySelection) {
        result.push(emptySelection)
      }
      if (invalidEmail) {
        result.push(invalidEmail)
      }
      return result.join('. ')
    },
    [emptyReceiptName, emptySelection, invalidEmail]
  )
}

export const actionEditAndDelete = [ACTION_EDIT, ACTION_DELETE]

export const areasAccessor = ({ original: { areas } }) => areas

export const hiddenColumnId = { accessor: 'id', Cell: () => null }

export const nameColumn = { Header: TXT_NAME, accessor: 'name' }

export const areasColumn = {
  Header: TXT_AREAS,
  accessor: arrayLengthAccessors('areas'),
  areasAccessor,
  id: 'areas',
  type: CELL_AREAS
}

export const activeSortType = (
  { original: { active } },
  { original: { active: activeB } }
) => activeB - active

export const columns = [
  hiddenColumnId,
  nameColumn,
  areasColumn,
  {
    Header: TXT_ACCOUNTS,
    accessor: arrayLengthAccessors('accounts'),
    accountsAccessor: ({ original: { accounts } }) => accounts,
    id: 'accounts',
    type: CELL_ACCOUNTS
  },
  combineCells(
    {
      Header: TXT_ACTION,
      accessor: 'id',
      actions: actionEditAndDelete,
      disableActionsWhenRowActive: actionEditAndDelete,
      type: CELL_ACTION
    },
    {
      accessor: 'active',
      callbackValueGetter: ({ row: { original: { id } } }) => id,
      disableWhenRowActive: true,
      id: 'active',
      sortName: TXT_ACTIVE,
      sortType: activeSortType,
      type: CELL_SWITCH
    }
  )
]

const List = ({
  activeChecker,
  byId,
  hiddenColumns,
  list,
  onClickAction,
  onSortChange,
  orgArea,
  servicesAndAccounts,
  sortBy
}) => (
  <NormalizedTable
    activeChecker={activeChecker}
    byId={byId}
    columns={columns}
    dataGetter={identity}
    hiddenColumns={hiddenColumns}
    list={list}
    orgArea={orgArea}
    onClickAction={onClickAction}
    onSortChange={onSortChange}
    onSwitchChange={useCallbackWithValue(ACTION_ACTIVATE, onClickAction)}
    pageSize={DEFAULT_PAGE_SIZE}
    servicesAndAccounts={servicesAndAccounts}
    sortBy={sortBy}
  />
)

export default List

export const ReceiptTypeDropdown = props => (
  <SimpleNormSingleSelect data={normType} {...props} />
)

export const ReceiptType = ({ disabled, onChange, selected }) => (
  <div className='left'>
    <DoubleDecks
      helper={<div>{TXT_SELECT_RECEIPT_TYPE}</div>}
      text={TXT_RECEIPT_TYPE}
    >
      <ReceiptTypeDropdown
        disabled={disabled}
        onSelect={onChange}
        selected={selected}
      />
    </DoubleDecks>
  </div>
)

export const useOnEditField = (onChange, field) => useCallbackMultiValues(
  onChange,
  field,
  AEO_EDIT
)

const StyledDiv = styled.div`
  div& {
    display: flex;
    .left, .right {
      width: 50%;
    }
    & + & {
      padding-top: 5px;
    }
  }
`
export const Div = composeWithDisplayName(
  'Div',
  withUnmountWhenHidden,
  memo
)(StyledDiv)

const TypeAndActiveInputBase = ({
  disabledReceiptType,
  onChange,
  onTypeChange,
  selected,
  active
}) => (
  <Div>
    <ReceiptType
      disabled={disabledReceiptType}
      onChange={onTypeChange}
      selected={selected}
    />
    <div className='right'>
      <DoubleDecks
        helper={<div>{TXT_ACTIVATE_INACTIVE_THIS_RECEIPT}</div>}
        text={TXT_ACTIVE}
      >
        <PlainSwitchCheckbox
          active={active}
          onClick={useToggleBool(useOnEditField(onChange, 'active'))}
        />
      </DoubleDecks>
    </div>
  </Div>
)

export const TypeAndActiveInput = memo(TypeAndActiveInputBase)

export const InputName2 = ({ name, onChange, title }) => (
  <DoubleDecks text={title}>
    <Input onChange={onChange} value={name} />
  </DoubleDecks>
)

export const InputName = ({ name, onChange, title }) => (
  <DoubleDecks text={title}>
    <Input onChange={onChange} value={name} />
  </DoubleDecks>
)

const EmailHelper = ({ children }) => (
  <div>{children || TXT_RECEIPT_FROM_EMAIL}</div>
)

const ReceiptNameInputBase = ({ name, onChange }) => (
  <Div>
    <div className='left'>
      <InputName name={name} onChange={useOnEditField(onChange, 'name')} title={TXT_RECEIPT_NAME} />
    </div>
  </Div>
)

const NameAndEmailInputBase = ({ email, nameInAddress, onChange, txtEmailHelper }) => (
  <Div>
    <div className='left'>
      <DoubleDecks
        helper={<EmailHelper>{txtEmailHelper}</EmailHelper>}
        text={TXT_RECEIPT_ADDRESS}
      >
        <Input value={email} onChange={useOnEditField(onChange, 'email')} />
      </DoubleDecks>
    </div>
    <div className='right'>
      <InputName name={nameInAddress} onChange={useOnEditField(onChange, 'nameInAddress')}
        title={TXT_RECEIPT_ADDRESS_NAME} />
    </div>
  </Div>
)

export const NameAndEmailInput = memo(NameAndEmailInputBase)

export const ReceiptNameInput = memo(ReceiptNameInputBase)

export const ReceiptOrgAreaDropdown = OrgAreaDropdown

const AreasDropdown = omitProps(['orgs'])(ReceiptOrgAreaDropdown)

export const AreasSelection = ({ areas, data, onChange }) => (
  <div className='left'>
    <DoubleDecks
      helper={<div>{TXT_SELECT_AREAS}</div>}
      text={TXT_AREAS}
    >
      <AreasDropdown
        areas={areas}
        data={data}
        onSelect={useOnEditField(onChange, 'areas')}
      />
    </DoubleDecks>
  </div>
)

const parentService = { parent: 'service' }

const serviceAccountNorms = {
  service: {
    groupOnly: true,
    sub: (data, _, id) => ({
      key: id,
      nested: () => data[id].allIds
    })
  }
}

export const AccountsDropdown = ({
  accounts,
  onSelect,
  servicesAndAccounts,
  ...props
}) => {
  const norms = useMemo(
    () => {
      const serviceTypes = {}
      each(servicesAndAccounts, (_, serviceType) => {
        if (serviceType !== 'service') {
          serviceTypes[serviceType] = parentService
        }
      })
      return update(serviceAccountNorms, { $merge: serviceTypes })
    },
    [servicesAndAccounts]
  )
  return (
    <NormSelectDropdown
      data={servicesAndAccounts}
      groupSelect
      multiSelect
      norms={norms}
      onSelect={useCallback(
        (ids, serviceType) => {
          onSelect(update(accounts, { [serviceType]: { $set: ids } }))
        },
        [accounts, onSelect]
      )}
      selectAll
      selectNone
      selected={accounts}
      selectedText={useCountAsSelectedText(
        norms,
        servicesAndAccounts,
        accounts
      )}
      textNoItemSelected={TXT_SELECT_ACCOUNTS}
      {...props}
    />
  )
}

const StyledCkeditor = styled(Ckeditor)`
  border: 1px solid ${tintGrey};
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border-radius: ${inputBorderRadius};
  ${({ isFocused }) => isFocused ? focusedBorderStyled : ''}
  .cke {
    .cke_inner {
      .cke_top {
        border: 0px;
        border-radius: 6px 6px 0 0;
      }
      .cke_contents {
        border-radius: 0 0 6px 6px;
      }
    }
  }
`
const useFocus = () => {
  const [isFocused, setIsFocused] = useState()
  return [
    isFocused,
    useCallback(() => { setIsFocused(false) }, []),
    useCallback(() => { setIsFocused(true) }, [])
  ]
}

const RichEditorBase = ({
  fileArchiveImages,
  fontFamily,
  fontSize,
  id,
  insertionText,
  languages,
  onChange,
  onResetInsertionText,
  simpleToolbar,
  value
}) => {
  const [focused, onBlur, onFocus] = useFocus()
  return (
    <StyledCkeditor
      control
      data-qa-id={id}
      defaultFontFamily={fontFamily}
      fileArchiveImages={fileArchiveImages}
      height={ADMIN_CKE_DEF_HEIGHT}
      id={id}
      insertionText={insertionText}
      isFocused={focused}
      defaultFontSize={fontSize}
      defaultContent={value}
      spellLanguages={languages}
      onBlur={onBlur}
      onChange={useContentChange(onChange)}
      onFocus={onFocus}
      onKeydown={doNothing}
      onResetInsertionText={onResetInsertionText}
      simpleToolbar={simpleToolbar}
    />
  )
}

const RichEditor = withCkeditor(RichEditorBase)

const ContentBase = ({ helper, id, onChange, simpleToolbar, title, value }) => (
  <Div>
    <DoubleDecks helper={helper} text={title}>
      <RichEditor
        id={id}
        onChange={onChange}
        simpleToolbar={simpleToolbar}
        value={value}
      />
    </DoubleDecks>
  </Div>
)

export const Content = memo(ContentBase)

const CommonContentBase = withProps({ id: 'receipt-greeting' })(Content)

export const CommonContent = ({ onChange, ...props }) => (
  <CommonContentBase
    onChange={useOnEditField(onChange, 'content')}
    {...props}
  />
)

const ReceiveReceiptHelper = () => (
  <div>
    {I("Enter the text that should be sent to the customer in the receipt together with the customer's original email.")}<br />
    {I('You can use the following templates to put customer specific information in the receipt:')}
    <ul>
      <li>
        <b>{'{ERRANDID}'}</b><br />
        {I('Errand number assigned to the new email.')}
      </li>
      <li>
        <b>[name]</b><br />
        {I("Customer's name. If no name was specified in incoming email then [name] will be replaced by email address.")}
      </li>
      <li>
        <b>[email]</b><br />
        {I("Customer's email address.")}
      </li>
    </ul>
  </div>
)

const ReceiptMessage = ({ helper, onChange, simpleToolbar, value }) => (
  <CommonContent
    helper={helper}
    onChange={onChange}
    simpleToolbar={simpleToolbar}
    title={TXT_RECEIPT_MESSAGE}
    value={value}
  />
)

const SubjectAndMessageBase = ({
  helper,
  message,
  onChange,
  simpleToolbar,
  subject
}) => (
  // eslint-disable-next-line react/jsx-fragments
  <Fragment>
    <Div>
      <DoubleDecks text={TXT_RECEIPT_SUBJECT}>
        <Input value={subject} onChange={useOnEditField(onChange, 'subject')} />
      </DoubleDecks>
    </Div>
    <ReceiptMessage
      helper={helper}
      onChange={onChange}
      simpleToolbar={simpleToolbar}
      value={message}
    />
  </Fragment>
)

export const SubjectAndMessage = memo(SubjectAndMessageBase)

export const fieldSetter = (field, value) => ({ [field]: { $set: value } })

export const useCallbackWithUpdater = (callback, field) => useCallback(
  value => { callback(fieldSetter(field, value)) },
  [callback, field]
)

const useCallbackWithObjectField = (onCallback, field) => useCallback(
  value => { onCallback({ [field]: value }) },
  [onCallback, field]
)

const useEditInterval = (editTime, field, from, to, onPopAlert) => {
  const editInterval = useCallbackWithObjectField(editTime, field)
  const editFrom = useCallbackWithUpdater(editInterval, 'from')
  const editTo = useCallbackWithUpdater(editInterval, 'to')
  // TODO: improve algorithm to support overflow date time range.
  return [
    useCallback(newFrom => {
      if (to && newFrom > to) {
        onPopAlert(TXT_UNSUPPORTED_FROM_MORE_THAN_TO)
        return
      }
      editFrom(newFrom)
    }, [editFrom, onPopAlert, to]),
    useCallback(newTo => {
      if (from > newTo) {
        onPopAlert(TXT_UNSUPPORTED_FROM_MORE_THAN_TO)
        return
      }
      editTo(newTo)
    }, [editTo, from, onPopAlert])
  ]
}

const insertDateTimeSeperator = (seperator, s) => {
  if (typeof s !== 'string' || !s || s.indexOf(seperator) >= 0) {
    return s
  }
  return s.substring(0, 2) + seperator + s.substring(2)
}

const insertTimeColon = s => insertDateTimeSeperator(':', s)

const useRemoveSeperator = (seperator, callback) => useCallback(s => {
  callback(s.replace(seperator, ''))
}, [callback, seperator])

const useRemoveColon = callback => useRemoveSeperator(':', callback)

const NoColonTime = ({ onChange, selected, ...props }) => (
  <TimePicker
    onChange={useRemoveColon(onChange)}
    selected={insertTimeColon(selected)}
    {...props}
  />
)

const insertDateSlash = s => insertDateTimeSeperator('/', s)

const useRemoveSlash = callback => useRemoveSeperator('/', callback)

const NoSlashDate = ({ onChange, selected, ...props }) => (
  <DateWithoutYear
    onChange={useRemoveSlash(onChange)}
    selected={insertDateSlash(selected)}
    {...props}
  />
)

const DateTimeOptionBase = ({
  dateFrom,
  dateOption,
  dateTo,
  onChange,
  onPopAlert,
  timeFrom,
  timeOption,
  timeTo
}) => {
  const onEditTime = useCallbackMultiValues(onChange, 'time', AEO_MANUAL)
  const editTimeOption = useCallbackWithUpdater(onEditTime, 'time')
  const editDateOption = useCallbackWithUpdater(onEditTime, 'date')
  const [editTimeFrom, editTimeTo] = useEditInterval(
    onEditTime,
    'timeInterval',
    timeFrom,
    timeTo,
    onPopAlert
  )
  const [editDateFrom, editDateTo] = useEditInterval(
    onEditTime,
    'dateInterval',
    dateFrom,
    dateTo,
    onPopAlert
  )
  const isCustomTime = timeOption === DT_OPT_OUT_OF_CUSTOM ||
    timeOption === DT_OPT_CUSTOM
  const isCustomDate = dateOption === DT_OPT_OUT_OF_CUSTOM ||
    dateOption === DT_OPT_CUSTOM
  return (
    // eslint-disable-next-line react/jsx-fragments
    <Fragment>
      <Div>
        <div className='left'>
          <DoubleDecks
            helper={<div>{TXT_SELECT_TIME_OPTION}</div>}
            text={TXT_TIME}
          >
            <SimpleNormSingleSelect
              data={normTimeOption}
              onSelect={editTimeOption}
              selected={timeOption}
            />
          </DoubleDecks>
        </div>
        <div className='right'>
          <DoubleDecks hidden={!isCustomTime} text={TXT_DATE}>
            <SimpleNormSingleSelect
              data={normDateOption}
              onSelect={editDateOption}
              selected={dateOption}
            />
          </DoubleDecks>
        </div>
      </Div>
      <Div hidden={!isCustomTime}>
        <div className='left'>
          <DoubleDecks text={TXT_START_TIME}>
            <NoColonTime onChange={editTimeFrom} selected={timeFrom} />
          </DoubleDecks>
        </div>
        <div className='right'>
          <DoubleDecks text={TXT_END_TIME}>
            <NoColonTime onChange={editTimeTo} selected={timeTo} />
          </DoubleDecks>
        </div>
      </Div>
      <Div hidden={!isCustomTime || !isCustomDate}>
        <div className='left'>
          <DoubleDecks text={TXT_START_DATE}>
            <NoSlashDate onChange={editDateFrom} selected={dateFrom} />
          </DoubleDecks>
        </div>
        <div className='right'>
          <DoubleDecks text={TXT_END_DATE}>
            <NoSlashDate onChange={editDateTo} selected={dateTo} />
          </DoubleDecks>
        </div>
      </Div>
    </Fragment>
  )
}

const DateTimeOption = memo(DateTimeOptionBase)

const useSelectReoccurring = onChange => {
  const toggle = useOnEditField(onChange, 'reoccurring')
  const interval = useOnEditField(onChange, 'reoccurringInterval')
  return useCallback(v => {
    if (v === 0) {
      toggle(false)
      return
    }
    toggle(true)
    interval(v)
  }, [interval, toggle])
}

const displayPreviousVersionSelectedValue = selected => {
  if (typeof selected === 'number') {
    return TXT_NUMBER_DAYS.replace('{NUMBER}', selected)
  }
  return TXT_NO_SELECTION
}

export const Edit = ({
  data: {
    accounts,
    active,
    areas,
    content,
    name,
    email,
    nameInAddress,
    reoccurring,
    reoccurringInterval,
    subject,
    time: {
      time,
      date,
      timeInterval: {
        from,
        to
      },
      dateInterval: {
        from: dateFrom,
        to: dateTo
      }
    }
  },
  isNew,
  onChange,
  onPopAlert,
  onTypeChange,
  orgArea,
  servicesAndAccounts,
  services
}) => (
  // eslint-disable-next-line react/jsx-fragments
  <Fragment>
    <TypeAndActiveInput
      active={active}
      disabledReceiptType={!isNew}
      onChange={onChange}
      onTypeChange={onTypeChange}
      selected={RECEIVE_RECEIPTS}
    />
    <ReceiptNameInput name={name} onChange={onChange} />
    <NameAndEmailInput email={email} nameInAddress={nameInAddress} onChange={onChange} />
    <SubjectAndMessage
      helper={<ReceiveReceiptHelper />}
      onChange={onChange}
      message={content}
      subject={subject}
    />
    <Div>
      <AreasSelection
        areas={areas}
        data={orgArea}
        onChange={onChange}
      />
      <div className='right'>
        <DoubleDecks text={TXT_ACCOUNTS}>
          <AccountsDropdown
            accounts={accounts}
            onSelect={useOnEditField(onChange, 'accounts')}
            servicesAndAccounts={servicesAndAccounts}
            services={services}
          />
        </DoubleDecks>
      </div>
    </Div>
    <DateTimeOption
      dateFrom={dateFrom}
      dateOption={date}
      dateTo={dateTo}
      onChange={onChange}
      onPopAlert={onPopAlert}
      timeFrom={from}
      timeOption={time}
      timeTo={to}
    />
    <Div>
      <div className='left'>
        <DoubleDecks text={TXT_REOCCURENCE}>
          <IdValueSingleInteger
            data={reoccurenceOption}
            invalidSelectedText={displayPreviousVersionSelectedValue}
            onSelect={useSelectReoccurring(onChange)}
            selected={reoccurring ? reoccurringInterval : 0}
          />
        </DoubleDecks>
      </div>
    </Div>
  </Fragment>
)
